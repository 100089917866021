.types-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  border-radius: 30px;
  background: $light-wite;

  .btn {
    padding: 9.5px 20px;
    border-radius: 20px;
    transition: 0.25s;
    color: $grey;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      color: $white;
      background: rgba($color: $accent-green, $alpha: 0.75);
    }
    &.active {
      color: $white;

      background: $accent-green;
    }
  }
}
.cars-container {
  // background: blue;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px 5px;
  flex-wrap: wrap;
  padding: 20px;

  .product {
    width: 365px;
    // min-width: 300px;
    max-width: 365px;
    flex: 1 0 auto;
    border-radius: 30px;
    // padding-bottom: 24px;
    background: $light-wite;
    user-select: none;
    // background: red;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    .btn {
      // margin-bottom: 15px;
      button {
        background: #db6868;
      }
    }

    .price {
      padding: 0 24px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: $grey;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 14px;
      margin-top: auto;
      span {
        margin-left: 4px;
        color: $accent-green;
        font-weight: 800;
      }
    }
    .tags {
      display: flex;
      gap: 8px 16px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0 24px;
      margin-bottom: 17px;
      .tag {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        color: $grey;
      }
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      text-transform: uppercase;
      color: $grey;
      padding: 0 24px;
      margin-bottom: 16px;
    }
    .img {
      width: 100%;
      height: 185px;
      position: relative;
      overflow: hidden;
      border-radius: 30px;
      margin-bottom: 24px;
      img {
        user-select: none;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .timer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 6px 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $accent-green;
        color: $white;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        transition: 0.25s;

        svg {
          margin-right: 8px;
        }
      }

      &:hover {
        .timer {
          opacity: 0.1;
        }
      }
    }
  }
}
