.thanks {
  padding-top: 32px;
  &__title {
    margin-bottom: 24px;
    color: $text-primary-black;
    @include h3-fixel-desk;
    max-width: 1200px;
  }

  &__body {
    padding: 24px 16px;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 48px;
    li {
      min-width: 340px;
      transition: 0.25s;
      cursor: pointer;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          * {
            transition: 0.15s;
            fill: black;
          }
          margin-right: 8px;
        }
        &:hover {
          color: $accent-green;
          svg {
            * {
              fill: $accent-green;
            }
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.64px;
      }
    }
    &__title {
      @include p2-geologica-desk;
      margin-bottom: 16px;
    }
  }

  .socials {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
    li {
      width: 40px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #383838;
      cursor: pointer;
      transition: 0.25s;

      svg {
        * {
          transition: 0.25s;
        }
      }

      &:hover {
        svg {
          * {
            fill: $accent-green;
          }
        }
      }
    }
  }
}
