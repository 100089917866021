// $mobile-size: 768px;
$mobile-size: 900px;
$desk-md: 1750px;
$desk-hd: 1280px;

@mixin background {
  background: url('/src/imgs/yt_bg_min.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin input-font {
  @include buttons-fixel-desk;
  text-align: left;
  color: $text-primary-black;
}

@mixin mobile-text {
  font-family: Fixel;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
}

@mixin mob-menu-font {
  @include p1-geologica-desk;
  text-align: left;
}

// Fixel Desktop
@mixin h1-fixel-desk {
  font-family: Fixel;
  font-size: 60px;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: $mobile-size) {
    @include h1-fixel-mobile;
  }
}

@mixin h2-fixel-desk {
  font-family: Fixel;
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: $mobile-size) {
    @include h2-fixel-mobile;
  }
}

@mixin h3-fixel-desk {
  font-family: Fixel;
  font-size: 36px;
  font-weight: 600;
  line-height: 120%;
  @media (max-width: $mobile-size) {
    @include h3-fixel-mobile;
  }
}

@mixin h4-fixel-desk {
  font-family: Fixel;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  @media (max-width: $mobile-size) {
    @include h4-fixel-mobile;
  }
}

@mixin buttons-fixel-desk {
  font-family: Fixel;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  @media (max-width: $mobile-size) {
    @include buttons-fixel-mobile;
  }
}
// Fixel End

// Fixel Mobile

@mixin h1-fixel-mobile {
  font-family: Fixel;
  font-size: 30px;
  line-height: 120%;
  font-weight: 700;
}

@mixin h2-fixel-mobile {
  font-family: Fixel;
  font-size: 26px;
  font-weight: 700;
  line-height: 120%;
}

@mixin h3-fixel-mobile {
  font-family: Fixel;
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
}

@mixin h4-fixel-mobile {
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
}

@mixin buttons-fixel-mobile {
  font-family: Fixel;
  font-size: 12px;
  line-height: 130%;
  font-weight: 600;
}

// end Fixel Mobile

// Geologica Desktop
@mixin p1-geologica-desk {
  font-family: Geologica;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  @media (max-width: $mobile-size) {
    @include p1-geologica-mobile;
  }
}

@mixin p2-geologica-desk {
  font-family: Geologica;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  @media (max-width: $mobile-size) {
    @include p2-geologica-mobile;
  }
}

@mixin p3-geologica-desk {
  font-family: Geologica;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  @media (max-width: $mobile-size) {
    @include p3-geologica-mobile;
  }
}

@mixin captian1-geologica-desk {
  font-family: Geologica;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  @media (max-width: $mobile-size) {
    @include captian1-geologica-mobile;
  }
}

@mixin captian2-geologica-desk {
  font-family: Geologica;
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
  @media (max-width: $mobile-size) {
    @include captian1-geologica-mobile;
  }
}
// End
// Geologica Mobile

@mixin p1-geologica-mobile {
  font-family: Geologica;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
}

@mixin p2-geologica-mobile {
  font-family: Geologica;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
}

@mixin p3-geologica-mobile {
  font-family: Geologica;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

@mixin captian1-geologica-mobile {
  font-family: Geologica;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
}

@mixin captian2-geologica-mobile {
  font-family: Geologica;
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
}

// End
