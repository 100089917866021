.pad-content {
}
.week-offer {
  border-radius: 10px;
  background: var(--grey-green, #e5efed);
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 150px;
  overflow: hidden;
  .left {
    flex: 1 0 0;
    min-width: 0;
    padding: 32px 48px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      text-transform: uppercase;
      color: $accent-green;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: $grey;

      max-width: 75%;
    }
  }
  .right {
    flex: 1 0 0;
    min-width: 0;
    max-width: 35%;
    // background: blue;
    min-height: 100%;
    position: relative;
    .discount {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: 1;
    }
    .bg {
      width: 100%;
      height: 100%;
      border-radius: 6px 0 0 6px;
      overflow: hidden;
      position: relative;

      .timer {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: rgba($color: #222, $alpha: 1);

        span {
          mix-blend-mode: screen;
          font-size: 62px;
          background: rgba($color: #fff, $alpha: 0.5);
          font-weight: bold;
          // background: rgba($color: #222, $alpha: 1);
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // width: 100%;
          // height: 100%;
          padding: 5px 10px;
          border-radius: 6px;
        }
      }
      img {
        object-fit: cover;
      }
    }
  }
  // padding: ;

  @media (max-width: 1000px) {
    flex-direction: column;
    overflow: visible;
    position: relative;

    .left {
      p {
        max-width: 100%;
      }
    }
    .right {
      position: static;

      width: 100%;
      height: 115px;
      flex: 1 0 auto;
      max-width: 100%;

      .discount {
        transform: translate(-25%, -25%) rotate(-45deg);
        width: 75px;
        height: auto;
        svg {
          width: 100%;
          height: 100%;
        }
        top: 0;
      }
      .bg {
        // display: none;
      }
    }
  }
}
