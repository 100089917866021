@import '../mixins.scss';

.info-block {
  .white-bordered {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .info-block {
      &__info {
        // overflow: hidden;
        flex: 1 0 0;
        min-width: 0;
        padding: 48px 48px 54px 24px;
        @include background;
        position: relative;
        color: #ffff;
        max-width: 918px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
        }
        .ii {
          position: relative;
          z-index: 1;
        }
        &__title {
          font-family: Fixel;
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 24px;
          line-height: 120%;
          text-transform: uppercase;
        }
        &__text {
          margin-bottom: 48px;
          p {
            font-family: Geologica;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        button {
          background: #fff;
          border-radius: 30px;

          font-size: 16px;
          font-weight: 500;
          line-height: 120%;
          color: rgba(34, 34, 34, 1);
          padding: 14px 24px;
        }
      }
      &__img {
        flex: 1 0 0;
        min-width: 0;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .info-block {
    .white-bordered {
      position: relative;
      flex-direction: column;
      .info-block__info {
        padding: 24px 16px;
        background: transparent;
        width: 100%;
        position: relative;
        z-index: 2;
        order: 2;
        @include background;
        border-radius: 16px;
        margin-top: -16px;
        &__title {
          width: 100%;
          @include h2-fixel-mobile;
        }
        &__text {
          p {
            @include p3-geologica-mobile;
          }
          margin-bottom: 32px;
        }
        button {
          @include buttons-fixel-mobile;
        }
      }
      .info-block__img {
        width: 100%;
        height: 180px;
        min-height: 180px; // doesnt work with flex standart height
        order: 1;
        filter: brightness(0.85);
        img {
          object-fit: cover;
        }
      }
    }
  }
}
