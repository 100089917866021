.forms {
  .forms-input {
    @include input-font;
    min-width: 150px;
    .flag-dropdown {
      border-radius: 30px 0px 0px 30px;
      height: 50px;
      background-color: white;
      border: unset;
      padding-left: 8px;
      @media (max-width: $mobile-size) {
        height: 100%;
      }
      .selected-flag {
        border-radius: 30px 0px 0px 30px;
      }
    }
    .form-control {
      padding-left: 56px !important;
    }
  }
  .react-tel-input {
    width: unset;
  }
}
