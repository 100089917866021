@import '../mixins';
.hero_cars_block {
  .white-bordered {
    position: relative;
    padding: 48px 32px;
    font-family: Geologica;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include background;
      filter: brightness(0.8);
    }

    .hot-cars__title {
      position: relative;
      z-index: 1;
      margin-bottom: 74px;

      h2 {
        @include h2-fixel-desk;
        color: #fff;
        padding: 0;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: $mobile-size) {
          text-align: left;
        }
      }

      p {
        color: #fff;
        width: 100%;
        @include p3-geologica-desk;
        text-align: center;
        @media (max-width: $mobile-size) {
          text-align: left;
        }
      }
    }

    .catalog_block {
      width: 100%;
      background-color: #4a994d;
      height: 106px;
      padding: 0px 12.23px 0px 32px;
      border-radius: 24px;
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      @media (max-width: 1760px) {
        flex-wrap: wrap;
        height: auto;
        align-content: center;
        justify-content: center;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          -70deg,
          rgba(255, 255, 255, 0) 46%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0.8) 52%,
          rgba(255, 255, 255, 0) 56%
        );
        animation: shine 4s ease-in-out infinite;
        z-index: 1;
      }
      .img {
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 920px;
        @media (max-width: 1760px) {
          height: 106px;
          width: 100%;
        }
        @media (max-width: 768px) {
          height: 70px;
          width: 490px;
          min-width: 327px;
        }
        @media (max-width: 490px) {
          height: 50px;
          width: 327px;
          min-width: 327px;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 71px;
        padding: 33px 0px;
        flex: 0 0 auto;
        p {
          font-family: Fixel;
          font-size: 48px;
          font-weight: 700;
          line-height: 57.6px;
          text-align: center;
          color: #fff;
          margin-right: 24px;
          @media (max-width: 1000px) {
            font-size: 38px;
          }
          @media (max-width: 800px) {
            font-size: 22px;
            margin-right: 8px;
          }
          @media (max-width: 490px) {
            @include mobile-text;
          }
        }

        .icon {
          transition: 0.25s;
          * {
            transition: 0.25s;
            fill: $white;
            @media (max-width: $mobile-size) {
              width: 12px;
              height: 12px;
            }
          }
        }

        @media (max-width: 1760px) {
          margin-right: 0px;
        }
        @media (max-width: $mobile-size) {
          padding: 22px 0px;
        }
      }
    }

    .cars-container {
      margin-top: 32px;
      width: 100%;
      padding-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // grid-template-columns: repeat(5, 1fr);
      gap: 25px; /* расстояние между карточками */
      row-gap: 64px;
      grid-auto-rows: minmax(100px, auto);

      @media (max-width: $mobile-size) {
        row-gap: 48px;
      }
      .empty {
        width: 100%;
        text-align: center;
        // font-size: ;
      }

      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background: rgba($color: $grey, $alpha: 0.3);
        // border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $accent-green;
        border-radius: 10px;
      }

      @media (max-width: 768px) {
        padding: 0px 12px;
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .hero_cars_block {
    .white-bordered {
      padding: 24px 12px;
      .hot-cars__title {
        margin-bottom: 64px;
      }
    }
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  100% {
    left: 100%;
    transition-property: right;
  }
}
