.about-hero {
  height: 100dvh;
  border-radius: 0px 0px 20px 20px;
  background-color: $green-100;
  position: relative;
  background: no-repeat center/cover url('/src/imgs/about/bg_about_page.jpg')
    rgba(0, 0, 0, 0.5);
  margin-bottom: 24px;
  overflow: hidden;
  @media (max-width: 1201px) {
    height: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: brightness(0.4);
  }
  &__body {
    position: absolute;
    z-index: 1;
    left: 108px;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    color: $white;
    width: 940px;

    @media (max-width: 1201px) {
      width: 100%;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      padding: 108px 10px 0px 10px;
    }
    h1 {
      @include h1-fixel-desk;
      text-transform: uppercase;
      margin-bottom: 48px;
      text-align: left;
      @media (max-width: 1201px) {
        margin-bottom: 12px;
      }
    }
    p {
      max-width: 892px;
      @include p1-geologica-desk;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__text {
    background-color: #ffffff80;
    backdrop-filter: blur(7px);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 64px;
    @media (max-width: 1201px) {
      padding: 16px 12px;
      margin-bottom: 32px;
    }
  }
  &__inputs {
    display: flex;
    align-items: center;
    .forms {
      display: flex;
      @media (max-width: 1201px) {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 38px;
      }
      input {
        &::placeholder {
          @include input-font;
        }
        width: 220px;
        height: 53px;
        padding: 16px 24px 16px 24px;
        border-radius: 25px;
        margin-right: 24px;
        color: $text-primary-black;
        @media (max-width: 1201px) {
          height: 36px;
          padding: 10px 20px 10px 20px;
          gap: 10px;
          border-radius: 21px;
        }
      }
      button {
        width: 208px;
        height: 53px;
        padding: 16px 24px 16px 24px;
        gap: 8px;
        border-radius: 40px;
        background-color: $light-green;
        @include input-font;
      }

      .flag-dropdown {
        height: 100%;
      }
    }
  }
}
