.rews-block {
  .padd-container {
    .white-bordered {
      .youtubs_block__title {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;

        h2 {
          @include h2-fixel-desk;
          text-align: center;
        }

        .block {
          p {
            max-width: 750px;
            @include p3-geologica-desk;
            text-align: left;
          }
        }

        .rews-title {
          color: black;
        }

        .p {
          color: black;
        }
      }

      .feedback-slug {
        width: 480px;
        height: 320px;
        border-radius: 24px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .rews_block__content {
        max-width: 100%;
        display: flex;
        // flex-direction: column;
        align-items: stretch;
        justify-content: center;
        gap: 32px;

        .rew {
          flex: 1 0 0;
          width: 208px;
          height: 370px;

          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 3/4;
          position: relative;
          border-radius: 16px;
          overflow: hidden;

          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            transition: 0.25s;
          }
          &::after {
            z-index: 1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($color: #000000, $alpha: 0.25);
            transition: 0.25s;
            border-radius: 16px;
          }

          &.playd {
            .play {
              opacity: 0;
            }
            &::after {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .rews-block {
    .padd-container {
      .white-bordered {
        .youtubs_block__title {
          align-items: start;
          // justify-content: unset;
          gap: 16px;
          .block {
            p {
              font-size: 16px;
            }

            .btns {
              gap: 6px;
              a {
                width: 32px;
                height: 32px;
                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }

        .rews_block__content {
          .rew {
            height: 370px;
            width: 100%;
            flex: 1 0 100%;
            max-width: 100%;
            &::after {
              background: transparent;
            }

            video {
              height: 100%;
            }
          }
        }

        .rews_block__feedback {
          height: 230px;
          .swiper-slide {
            height: 230px !important;
            @media (max-width: 530px) {
              width: 100% !important;
            }
            // width: 100% !important;
            .feedback-slug {
              height: 230px;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
