.leads_bot {
  width: 100%;
  padding: 15px;

  h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .users-table {
    .head,
    .item {
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: 1fr;
      // gap: 0px 0px;
      // grid-template-areas: ". . . . .";

      display: flex;
      justify-content: flex-start;
      .key {
        flex: 1 0 0;
        padding: 5px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: rgba($color: #fff, $alpha: 0.6);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
    .head {
      background: #f79646;
      color: #fff;
      font-weight: bold;
    }
    .item {
      &:nth-child(odd) {
        background: rgba($color: #8b8b8a, $alpha: 0.23);
      }
      // }
      // &:nth-child(even) {

      .key {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .btn {
          cursor: pointer;
          transition: 0.25s;
          font-weight: bold;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            transition: 0.25s;
            background: #000;
          }
          &:hover {
            color: #f79646;
            &::after {
              background: #f79646;
            }
          }
        }
        &::after {
          background: rgba($color: #000, $alpha: 0.15);
        }
      }
    }
  }
  .send-msg {
    .block-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .send-msg-to {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .select-tag {
          width: 250px;
          position: relative;
          .head,
          .item {
            border: 1px solid rgba($color: #000000, $alpha: 0.45);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 18px;
            padding: 6px;
            padding-left: 10px;
            background: #fff;
            cursor: pointer;
          }
          .head {
            border-radius: 6px;
          }
          .item {
            border-top: none;
          }
          .sel-list {
            position: absolute;
            left: 0;
            top: 100%;
            // background: #fff;
            width: 100%;
            transition: 0.25s;
            opacity: 0;
            pointer-events: none;
          }

          &.show {
            .sel-list {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
      .info {
        padding-left: 10px;
        color: rgba($color: #000000, $alpha: 0.65);
        font-size: 12px;
        margin: 15px 0;
        margin-top: -5px;
      }
      textarea {
        width: clamp(200px, 400px, 100%);
        resize: none;
        height: 250px;
        outline: none;
        border-radius: 5px;
        padding: 15px;

        margin-bottom: 15px;
        box-shadow: 0 0 12px 3px rgba($color: #000000, $alpha: 0.05);
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
      input {
        margin-bottom: 15px;
        visibility: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
      }
      .img-prew {
        width: clamp(200px, 400px, 100%);
        position: relative;
        height: 250px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);

        margin-bottom: 15px;
        box-shadow: 0 0 12px 3px rgba($color: #000000, $alpha: 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      button {
        padding: 5px 10px;
        background: rgba(31, 41, 55, 1);
        color: #fff;
        border: 0;
        transition: 0.25s;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background: rgba(31, 41, 55, 0.75);
        }
      }
    }
  }
  .navs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
    .users-count {
      font-size: 21px;
    }

    .switch {
      // padding: 8px;
      // background: red;
      background: #999;
      color: #999;
      background: rgba(31, 41, 55, 0.655);
      box-shadow: 0 0 12px 3px rgba($color: #000000, $alpha: 0.05);
      // border: 1px solid rgba($color: #000000, $alpha: 0.35);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .holder {
        display: block;
        padding: 8px;
        transition: 0.25s;
        overflow: hidden;
        position: relative;
        user-select: none;
        // border-radius: 5px;
        cursor: pointer;
        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: pink;
          background: rgba(31, 41, 55, 1);

          transition: 0.25s;
        }
        span {
          position: relative;
          z-index: 1;
        }
        &:nth-child(1) {
          // background: blue;
          border-radius: 5px 0 0 5px;

          &::after {
            border-radius: 5px 0 0 5px;

            left: 100%;
          }
        }
        &:nth-child(2) {
          border-radius: 0 5px 5px 0;
          &::after {
            border-radius: 0 5px 5px 0;

            left: -100%;
          }
        }

        &.active {
          color: #fff;
          &::after {
            left: 0;
          }
        }
      }
    }
  }
}
