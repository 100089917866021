.block {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background: $white;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
  }

  .contacts {
    .contacts-list {
      border-radius: 20px;
      // background: rgba(239, 239, 239, 1);
      background: rgba($color: $grey, $alpha: 0.7);
      padding: 24px;
      color: $white;
      width: 100%;
      height: 100%;
      max-width: 350px;
      margin-bottom: 25px;
      margin-top: -10px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 290px;
        transition: 0.25s;
        cursor: pointer;
        // display: block;
        margin-bottom: 15px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          content: 'Удалить';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba($color: #000000, $alpha: 0.7);
          display: flex;
          font-size: 12px;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.25s;
          opacity: 0;
          align-items: center;
          justify-content: center;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.64px;
          svg {
            * {
              transition: 0.15s;
            }
            margin-right: 8px;
          }
        }
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
    .add {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 25px;

      input {
        width: 100%;
      }
      .select div {
        width: 100%;

        border-radius: 8px;
        border: 2px solid $accent-green;
        background: $light-wite;
        padding: 16px 32px;
        padding: 9px 10px;
        padding-right: 13px;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        outline: none;

        color: $grey;
      }
      .select {
        position: relative;
        outline: none;
        width: 100%;

        div {
          position: relative;
          // z-index: 999;
          // position: relative;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 7px 0 7px;
            border-color: $grey transparent $grey transparent;
            position: absolute;
            margin-top: 3px;
            margin-left: 10px;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.25s;
          }
        }
        &:active,
        &:focus {
          div {
            &::after {
              // transform: translateY(-50%) rotate(180deg);
              border-color: $accent-green transparent $accent-green transparent;

              border-width: 0px 7px 10px 7px;
            }
          }
        }
        ul {
          position: absolute;
          width: 100%;
          height: auto;
          top: calc(100% - 2px);
          left: 0;
          border: 2px solid $accent-green;
          background: $light-wite;
          border-radius: 8px;
          max-height: 300px;
          overflow-y: auto;
          cursor: pointer;
          overflow-x: hidden;
          user-select: none;
          z-index: 9;
          // position: relative;

          li {
            cursor: pointer;
            font-size: 16px;
            transition: 0.25s;
            padding: 16px 32px;
            cursor: pointer;
            border-bottom: 1px solid rgba($color: $grey, $alpha: 0.14);
            &:last-child {
              border-bottom: 0px;
            }

            &:hover {
              background: $accent-green;
              color: $white;
            }
          }
        }
      }
    }
  }
  .add {
    max-width: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    input {
      border: 1px solid $grey;
      border-radius: 6px;
      padding: 6px 10px;
    }
    button {
      background: rgba($color: $accent-green, $alpha: 1);
      padding: 6px 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      color: $white;
      transition: 0.25s;
      border-radius: 10px;
      cursor: pointer;

      //     text-align: center;
      // font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }

    &.big-add {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .users {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    max-width: 350px;
    // background: red;
    .user {
      width: 100%;
      display: flex;
      // align-items: flex-start;
      align-items: center;
      justify-content: space-between;
      background: $light-wite;
      padding: 10px;
      border-radius: 20px;
      .btns {
        margin-left: 15px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7px;
          color: $white;
          transition: 0.25s;
          background: rgba($color: #db6868, $alpha: 1);
          border-radius: 20px;
          padding: 7.5px 10px;
          cursor: pointer;

          //     text-align: center;
          // font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
        }
      }
      .id {
        margin-left: auto;
        font-size: 14px;
        color: $grey;
      }
      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;

        span {
          &:last-child {
            margin-top: -6px;
            font-size: 12px;
            color: rgba($color: $grey, $alpha: 0.8);
          }
        }
      }
    }
  }
}

.car-brands {
  .add {
    margin-bottom: 16px;
  }
  .car-brand {
    display: flex;
    align-items: center;
    max-width: 350px;
    margin-bottom: 12px;
    padding: 6px;
    padding-left: 24px;
    background-color: #f6f6f6;
    border-radius: 16px;
    color: black;
    span {
      margin-right: auto;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      color: #fff;
      transition: 0.25s;
      background: #db6868;
      border-radius: 20px;
      padding: 7.5px 10px;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
