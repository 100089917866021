.auto-delivery {
  h2 {
    text-align: left;

    @media (max-width: 1000px) {
      font-size: 36px;
    }
  }
  .products-example {
    padding: 48px 32px;
    @media (max-width: 1000px) {
      padding: 24px 0px;
      padding-right: 0;
    }
    h2 {
      max-width: 545px;
    }
  }

  .photos-example {
    width: 100%;
    padding-top: 48px;
    h2 {
      padding: 0 32px;
    }
    @media (max-width: 1000px) {
      padding-top: 24px;
      h2 {
        padding: 24px 0;
      }
    }
    .photos {
      padding: 5px 24px;
      padding-bottom: 10px;
      max-width: 100%;
      overflow-x: scroll;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      gap: 12px;
      user-select: none;

      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background: rgba($color: $grey, $alpha: 0.3);
        // border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: $accent-green;
        border-radius: 10px;
      }

      img {
        object-fit: cover;
        user-select: none;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
      }
      .img {
        user-select: none;
        position: relative;
        min-width: 275px;
        height: auto;
        aspect-ratio: 9/13;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          // background: red;
        }
      }
    }
  }
  @import './heroBlock.scss';
  @import './expenses.scss';
  @import './process.scss';
  @import './faq.scss';
  @import './textBlock.scss';
}
