@import '../mixins.scss';
header {
  position: absolute;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 9;
  padding: 16px 32px;
  transition: 0.25s;
  .lft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.25s;
  }
  .logo {
    width: 56px;
    height: 36px;
    margin-right: 100px;

    @media (max-width: 1141px) {
      margin-right: 10px;
    }
    @media (max-width: 768px) {
      width: 44px;
      height: 28.29px;
      margin-right: unset;
    }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      * {
        transition: 0.25s;
      }
    }
    &:hover {
      svg {
        * {
          fill: $light-green;
        }
      }
    }
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    color: $white;

    @media (max-width: 1141px) {
      gap: 17px;
    }

    a {
      transition: 0.25s;
      // color: inherit;
      white-space: nowrap;
      position: relative;
      @include buttons-fixel-desk;

      &:hover {
        color: $light-green;
      }
      &.active {
        color: $light-green;
      }
      @media (max-width: 1141px) {
        font-size: 14px;
      }
    }

    .with-sub {
      position: relative;
      &:hover {
        .submenu {
          pointer-events: all;
          opacity: 1;
        }
      }
      // background: red;
    }
    .submenu {
      position: absolute;
      top: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);
      background: $grey;
      opacity: 0;
      pointer-events: none;
      transition: 0.25s;
      color: $white;
      width: auto;
      min-width: 120px;
      padding: 5px 0;
      border-radius: 8px;
      cursor: default;
      &:hover {
        opacity: 1;
        pointer-events: all;
      }
      li {
        padding: 2px 16px;
        cursor: pointer;

        a {
          display: block;
          white-space: nowrap;
          width: 100%;
          color: $white;
        }
        &:hover {
          a {
            color: $light-green;
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        height: 16px;
        width: 100%;
        background: transparent;
        left: 0;
      }
    }
  }
  .btn-request {
    background: $white;
    color: $text-primary-black;
    margin-left: auto;
    // width: Hug (220px)px;
    // height: Hug (45px)px;
    padding: 12px 32px 12px 32px;
    gap: 8px;
    border-radius: 40px;
    font-family: Fixel;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;

    .icon {
      * {
        fill: $grey;
      }
    }

    &:hover {
      color: $white;
      background: $light-green;
      .icon {
        * {
          fill: $white;
        }
      }
    }
    @media (max-width: 1141px) {
      white-space: nowrap;
      .icon {
        display: none;
      }
      font-size: 14px;
    }

    @media (max-width: 768px) {
      width: 159px;
      height: 36px;
      padding: 10px 20px 10px 20px;
      gap: 8px;
      border-radius: 40px;
      font-family: Fixel;
      font-size: 12px;
      font-weight: 600;
      line-height: 15.6px;
      text-align: center;
    }
  }
  &.static-header {
    position: relative;
    top: 0;
    padding: 16px 32px;
    background: #222222;
    .lft {
      color: $white;
      border-radius: 40px;
      .menu {
        a {
          color: $white;
          @include buttons-fixel-desk;
          &:hover {
            color: $light-green;
          }
          &.active {
            color: $light-green;
          }
        }
        .submenu {
          li {
            a {
              color: $white;
              &:hover {
                color: $light-green;
              }
            }
          }
        }
      }
      .logo {
        svg {
          * {
            fill: $white;
          }
        }
        &:hover {
          svg {
            * {
              fill: $light-green;
            }
          }
        }
      }
    }
    .btn-request {
      background: $white;
      color: $text-primary-black;

      .icon {
        * {
          color: $text-primary-black;
        }
      }

      &:hover {
        background: rgba($color: $light-green, $alpha: 0.75);
      }
    }
  }
  &.pre-scrolled {
    top: -90px;
    position: fixed;
    background: transparent;
    transition: 0s;

    .submenu {
      opacity: 0 !important;
      pointer-events: none !important;
    }

    &.static-header {
      & + .head-holder {
        position: relative;
        top: 0;
        padding: 48px 16px;
        // padding-top: 24px;
        padding-top: 0;
        margin-top: 24px;
        height: 88px;
      }
    }
  }

  &.scrolled {
    position: fixed;
    top: 0;
    background: #222222;

    .logo {
      &:hover {
        svg {
          * {
            fill: rgba($color: $white, $alpha: 0.5);
          }
        }
      }
    }
    .menu {
      a {
        color: $white;
        &:hover {
          color: rgba($color: $white, $alpha: 0.7);
        }
      }
    }
    &.static-header {
      margin-top: 0;
      .lft {
        padding: 0;
        background: transparent;
        .logo {
          svg {
            * {
              fill: $white;
            }
          }
        }
        .menu {
          a {
            color: $white;
            &:hover {
              color: rgba($color: $white, $alpha: 0.7);
            }
          }
        }
      }

      .btn-request {
        background: $white;
        color: $grey;
        .icon {
          * {
            fill: $grey;
          }
        }

        &:hover {
          color: $white;
          background: $light-green;
          .icon {
            * {
              fill: $white;
            }
          }
        }
      }

      & + .head-holder {
        position: relative;
        top: 0;
        padding: 48px 16px;
        // padding-top: 24px;
        padding-top: 0;
        margin-top: 24px;
        height: 88px;
      }
    }

    // background: rgba(0, 97, 74, 0.6);
    // backdrop-filter: blur(50px);
    // padding: 8px 32px;
  }

  &.preload {
    transition: 0s;
    opacity: 0;
  }

  @media (max-width: 1000px) {
    justify-content: space-between;
    .menu {
      display: none;
    }
    .logo {
      z-index: 3;
      svg {
        * {
          fill: $white;
        }
      }
    }
    .btn-request {
      margin-left: auto;
      margin-right: auto;
      z-index: 3;
    }
    .mob-btn {
      z-index: 3;
      width: 48px;
      height: 48px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff80;
      transition: 0.25s;
      position: relative;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        // transform: translate(-50%);
        translate: -50% -50%;
        rotate: 45deg;
        width: 3px;
        border-radius: 8px;
        height: 24px;
        background: $light-green;
        transition: 0.25s;
        opacity: 0;
      }
      svg {
        transition: 0.25s rotate 0.25s 0.25s;

        @media (max-width: 768px) {
          width: 18px;
          height: 14px;
        }
      }

      svg {
        width: 100%;
        height: auto;
        max-width: 19px;
        transition: 0.25s;
      }
      @media (max-width: 768px) {
        width: 36px;
        height: 36px;
        border-radius: 24px;
      }
    }

    .mob-menu {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      // height: 100bvh;
      background: #1b691e;
      padding-top: 52px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      transition: 0.25s;

      opacity: 0;
      pointer-events: none;
      .menu-list {
        // background: red;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding-top: 35px;
        gap: 16px 0;
        a {
          width: 100%;
          font-size: 32px;
          padding-left: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 48px */
          letter-spacing: 1.28px;
          color: $white;
          @include mob-menu-font;
        }

        .submenu {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          .title {
            @include mob-menu-font;
            width: 100%;
            padding-left: 16px;
            color: $white;
            width: 100%;

            position: relative;
            &::after,
            &::before {
              content: '';
              position: absolute;
              right: 10px;
              top: 50%;
              translate: -50%;
              width: 20px;
              height: 2px;
              background: $white;
              border-radius: 6px;
              transition:
                0.25s,
                rotate 0.25s 0.25s;
            }
            &::before {
              rotate: 90deg;
            }
            // background: red;
          }

          .mini-menu {
            width: 100%;
            display: flex;
            // display: none;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            // height: 100%;
            gap: 8px 0;
            transition: 0.25s;

            overflow: hidden;
            max-height: 0;
            padding-top: 0;
            a {
              padding-left: 35px;
              font-size: 24px;
            }
          }

          &.opened {
            .title {
              &::before {
                transition:
                  0.25s,
                  opacity 0.25s 0.25s;

                rotate: 360deg;
                opacity: 0;
              }
            }
            .mini-menu {
              padding-top: 15px;
              max-height: 280px;
              overflow-y: auto;
            }
          }
        }
      }

      &__tel {
        position: absolute;
        bottom: 32px;
        left: 16px;
        color: white;
        @include h3-fixel-mobile;
      }

      &__link {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          path {
            stroke: white;
            stroke-opacity: 1;
          }
        }
      }
    }

    &.static-header {
      .lft {
        background: transparent;
        padding-left: 0;
        padding-right: 0;
      }
      .logo {
        margin-right: 0;
      }
      .btn-request {
        background: $white;
      }
      .mob-btn {
        background: $grey;
      }

      &.scrolled {
        .mob-btn {
          background: $white;
          background: rgba(255, 255, 255, 0.4);

          svg {
            * {
              // fill: $light-green;
            }
          }
        }
      }
    }
    &.pre-scrolled {
      &.static-header {
        & + .head-holder {
          position: relative;
          top: 0;
          padding: 0px 16px;
          // padding-top: 24px;
          padding-top: 0;
          margin-top: 24px;
          height: 100px;
        }
      }
    }

    &.scrolled {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.open-mob-menu {
      .lft {
        .logo {
          a {
            svg {
              * {
                fill: $white;
              }
            }
          }
        }
      }
      .btn-request {
        background: $white;
        color: $light-green;
      }

      .mob-btn {
        background: $white;

        &::after,
        &::before {
          opacity: 1;
        }
        &::after {
          rotate: calc(90deg + 220deg);
        }
        &::before {
          rotate: calc(45deg + 180deg);
        }
        svg {
          opacity: 0;
        }
      }

      .mob-menu {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
