.signin {
  display: flex;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .signin-container {
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    padding: 25px;
    border-radius: 15px;
    input {
      width: 100%;
      border: 1px solid rgba($color: $grey, $alpha: 0.25);
      padding: 8px 12px;
      border-radius: 8px;
      transition: 0.25s;
      outline: none;
      &:active,
      &:focus {
        border-color: rgba($color: $grey, $alpha: 0.85);
      }
    }
    button {
      width: 100%;
      text-align: center;
      padding: 8px 12px;
      background: $accent-green;
      color: $white;
      text-transform: uppercase;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 1px;
      transition: 0.25s;
      &:hover {
        background: rgba($color: $accent-green, $alpha: 0.75);
      }
    }
  }
}
