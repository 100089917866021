$change-maket: 1506px;
.info-block {
  margin-top: 48px;
  display: flex;
  gap: 24px;
  height: 549px;
  padding: 0px 16px;
  @media (max-width: $change-maket) {
    flex-direction: column;
    height: unset;
    border-radius: 16px;
  }

  .auto-from-usa {
    height: 100%;
    width: 826px;
    flex: 1 0 826px;
    padding: 24px;
    gap: 32px;
    border-radius: 24px;
    background-color: gray;
    position: relative;
    overflow: hidden;
    @media (max-width: $change-maket) {
      max-height: 574px;
      width: 100%;
      padding: 24px 12px;
    }

    .bg {
      img {
        filter: brightness(0.4);
        @media (max-width: $change-maket) {
          object-fit: cover;
        }
      }
    }

    .title {
      @include h2-fixel-desk;
      color: $white;
      margin-bottom: 24px;
      text-transform: uppercase;
      max-width: 560px;
      position: relative;
      z-index: 1;
      @media (max-width: $mobile-size) {
        margin-bottom: 12px;
      }
    }

    .text {
      background: rgba($color: $white, $alpha: 0.5);
      backdrop-filter: blur(5px);
      padding: 24px;
      border-radius: 12px;
      @include p2-geologica-desk;
      margin-bottom: 32px;
      color: $white;
      min-height: 276px;
      width: 658px;
      p {
        margin-bottom: 16px;
      }
      position: relative;
      z-index: 1;
      @media (max-width: $mobile-size) {
        @include p3-geologica-mobile;
        width: unset;
        height: 384px;
        padding: 16px 12px;
        margin-bottom: 24px;
      }
    }

    .button {
      background: #8fff00;
      width: 266px;
      height: 53px;
      padding: 16px 32px 16px 32px;
      border-radius: 40px;
      @include buttons-fixel-desk;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      color: $text-primary-black;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: black;
        }
      }
      @media (max-width: $mobile-size) {
        width: 210px;
        height: 44px;
        padding: 14px 28px 14px 28px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .no-risk {
    background-color: gray;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    @media (max-width: $mobile-size) {
      padding: 24px 12px;
      border-radius: 16px;
    }

    .title {
      @include h2-fixel-desk;
      color: $white;
      margin-bottom: 24px;
      @media (max-width: $mobile-size) {
        margin-bottom: 12px;
        height: 124px;
        overflow-wrap: break-word;
      }
    }

    .text {
      max-width: 610px;
      @include p2-geologica-desk;
      color: $white;
      margin-bottom: 32px;
      span {
        color: $light-green;
      }
      p {
        margin-bottom: 16px;
      }
      @media (max-width: $mobile-size) {
        @include p3-geologica-mobile;
        margin-bottom: 24px;
      }
    }

    .button {
      width: 283px;
      height: 53px;
      padding: 16px 32px 16px 32px;
      border-radius: 40px;
      background-color: $green-100;
      color: $white;
      @include buttons-fixel-desk;
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $white;
        }
      }
      @media (max-width: $mobile-size) {
        width: 222px;
        height: 44px;
        padding: 14px 28px 14px 28px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    @include background;
    &::after {
      content: '';
      display: block;
      background: no-repeat center/cover url('/src/imgs/Card.png');
      width: 424px;
      height: 409px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      @media (max-width: 1750px) {
        width: 200px;
        height: 200px;
      }
      @media (max-width: $mobile-size) {
        display: none;
      }
    }
  }
}
