.chat-head {
  width: 100%;
  padding: 14px;
  background: $accent-green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;

  .users-online {
    position: relative;
    .head {
      display: flex;
      padding-left: 20px;
      cursor: default;
      user-select: none;
      span {
        margin-right: 6px;
        display: block;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border: 2px solid $white;
          border-radius: 50%;
          box-shadow: 0 0 2px 3px rgba($color: #fff, $alpha: 0.3);
        }
      }
    }
    .user-list {
      position: absolute;
      top: 100%;
      left: 0;
      transform: translateY(15px);
      // background: $accent-green;
      border-top: 1px solid $white;
      // padding: 5px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      box-shadow: 0 2px 6px 2px rgba($color: #000000, $alpha: 0.24);

      transition: 0.25s;
      opacity: 0;
      pointer-events: none;
      z-index: 9;
      user-select: none;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        background: transparent;
        height: 15px;
        top: -16px;
        left: 0;
      }
      .user {
        display: flex;
        position: relative;
        padding: 12px 10px;
        border-bottom: 1px solid $white;
        display: flex;
        flex-direction: column;
        width: 250px;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
        transition: 0.25s;
        background: $accent-green;
        // backdrop-filter: blur(0px);

        &:hover {
          // backdrop-filter: blur(20px);
          // background: rgba($color: $accent-green, $alpha: 0.36);
          background: darken($color: $accent-green, $amount: 6);
        }
        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        // gap: 6px;
        .id {
          font-weight: 600;
          font-weight: 16px;
          margin-bottom: 4px;
          line-height: 100%;
        }
        .ip {
          font-size: 13px;
          line-height: 100%;
        }
        .role {
          position: absolute;
          right: 10px;
          letter-spacing: 1px;
          // top: 50%;
          // transform: translateY(-50%);
          top: 12px;
          // bottom: 12px;

          border-radius: 10px;
          border: 1px solid $white;
          padding: 3px;
          font-size: 10px;
        }
        //
        &.admin {
          color: #f0e68c;
          .role {
            border-color: #f0e68c;
          }
        }
      }
    }
    &:hover {
      .user-list {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}
