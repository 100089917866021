.spinner-container {
  width: 334px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0px;
}

.spinner-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
