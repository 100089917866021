.text-delivery {
  width: 100%;
  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .column {
      min-width: 0;
      flex: 1 0 0;
      position: relative;
      @media (max-width: 1000px) {
        flex: 1 0 auto;
      }

      .img {
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
        }
        @media (max-width: 1000px) {
          height: 200px;
        }
      }
      h2 {
        margin-bottom: 16px;
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.72px;
        color: $grey;
        margin-bottom: 24px;
        max-width: 75%;

        @media (max-width: 1000px) {
          width: 100%;
          max-width: 100%;
        }
      }
      &-text {
        padding: 48px 32px;
        @media (max-width: 1000px) {
          padding: 24px 12px;
        }
      }
    }

    &.with-img {
      .column {
        h2 {
          max-width: 765px;
        }
      }
    }
  }
}
