@import '../mixins';

.tous_block {
  .white-bordered {
    padding: 48px 32px;
    .tous__title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 48px;

      h2 {
        @include h2-fixel-desk;
        text-align: left;
        padding: 0;
        color: $text-primary-black;
        margin-bottom: 16px;
      }
      p {
        font-family: Geologica;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: right;
        color: $text-secondary;
        @media (max-width: $mobile-size) {
          @include p3-geologica-mobile;
          text-align: left;
        }
      }
    }

    .blocks {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      .tous_block__item {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 20px;

        color: #fff;

        position: relative;
        overflow: hidden;
        min-height: 240px;
        @include background;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          img {
            // display: ;
            object-fit: cover;
            z-index: 1;
          }
          &::after {
            z-index: 1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.5);
          }
        }

        &__title {
          font-family: 'Fixel';
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: 600;
          position: relative;
          z-index: 1;
          line-height: 28.8px;
        }
        &__text {
          font-family: Geologica;
          font-size: 18px;
          font-weight: 400;
          line-height: 23.4px;
          text-align: left;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  &.big_one {
    .white-bordered {
      .tous__title {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          text-align: center;
        }
      }

      .tous_block__item__head {
        font-weight: 500;
        font-size: 32px;
        line-height: 120%;
        font-family: 'Fixel';
        margin: 24px 0;
        width: 100%;
        text-align: center;
      }

      .blocks {
        // grid-template-columns: repeat(2, 1fr);
        // gap: 16px;

        .tous_block__item {
          min-height: 250px;
          // padding: 32px;
          // border-radius: 20px;
          // .tous_block__item__head {
          //     font-size: 24px;
          //     margin: 16px 0;
          // }
          .tous_block__item__text {
            font-size: 16px;
            line-height: 140%;
          }
        }
      }
      .btm_info {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btm_info__text {
          font-size: 16px;
          line-height: 140%;
          font-family: '__Roboto_68d44c';
          max-width: 45%;
        }

        button {
          padding: 14px 24px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
          font-family: '__Roboto_68d44c';
          background: rgba(27, 105, 30, 1);
          color: #fff;
          border: none;
          cursor: pointer;
          transition: 0.25s;
          &:hover {
            background: rgba(27, 105, 30, 0.81);
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .tous_block {
    .white-bordered {
      padding: 24px 12px;
      .tous__title {
        flex-direction: column;
        margin-bottom: 24px;
      }

      .blocks {
        // flex-direction: column;
        grid-template-columns: 1fr;
        .tous_block__item {
          padding: 18px;
        }
      }
    }

    &.big_one {
      .btm_info {
        flex-direction: column;
        .btm_info__text {
          font-size: 14px;
          max-width: 100% !important;
          width: 100% !important;
          margin-bottom: 12px !important;
          text-align: center;
        }
      }
    }
  }
}
