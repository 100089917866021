.statistic {
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1201px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1000px) {
    padding: 12px 0;
  }
  .column {
    // background: blue;
    min-width: 0;
    flex: 1 0 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    // gap: 0 16px;
    gap: 16px;
    @media (max-width: 1000px) {
      width: 100%;
      flex: 1 0 100%;
      flex-wrap: wrap;
    }
  }
  .dir-column {
    flex-direction: column;
    @media (max-width: 1201px) {
      width: 100%;
      flex: 1 0 100%;
    }
  }
  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    // gap: 0 16px;
    gap: 16px;
    flex: 1 0 auto;
    min-width: 0;
    // background: red;

    // height: 35%;
    // min-height: 35%;

    // &:first-child {
    //     height: calc(65% - 16px);
    //     min-height: calc(65% - 16px);
    //     margin-bottom: 16px;
    // }
    // .column {
    //     background: purple;
    // }
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      flex: 1 0 auto;
    }
  }

  .block {
    border-radius: 20px;
    background: $light-wite;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 348px;
    .img {
      width: 100%;
      margin-top: auto;
      height: 100%;
      min-height: 300px;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.graph {
        // display: flex;
        // align-items: flex-end;
        // justify-content: flex-start;
        height: auto;
        min-height: 150px;
        img {
          height: auto !important ;
          top: auto !important;
          bottom: 0 !important;
        }
      }
    }
    .content {
      width: 100%;
      padding: 24px;
      @media (max-width: 1481px) {
        padding: 16px;
      }
      .title {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 70%;
        color: $accent-green;
        margin-bottom: 16px;
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.72px;
        color: $grey;
      }
      .locations,
      .counrtys {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
        color: $grey;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.72px;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg {
            margin-right: 0px;
          }
        }
        @media (max-width: 1481px) {
          flex-wrap: wrap;
          gap: 16px 6px;
        }
      }

      .counrtys {
        flex-wrap: wrap;
        gap: 16px 12px;
        justify-content: flex-start;
        li {
          svg {
            margin-right: 5px;
          }
        }
      }

      .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 9px;

        .btn {
          width: calc(50% - 4.5px);
          border-radius: 30px;
          border: 1px solid $accent-green;
          background: $accent-green;
          color: $accent-green;
          transition: 0.25s;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 16px;
          cursor: default;
          user-select: none;

          @media (max-width: 1481px) {
            font-size: 13px;
            padding: 11px 8px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            background: $light-wite;
          }
          &:nth-child(1),
          &:nth-child(4) {
            color: $white;
          }
        }
      }
    }

    .imgs-container {
      width: 100%;
      position: relative;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      height: 100%;
      .pos {
        position: absolute;
        z-index: 2;
        bottom: 14px;
        // right: 0;
        color: $white;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px; /* 160.714% */
        letter-spacing: 0.56px;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: rgba($color: $accent-green, $alpha: 0.76);
        padding: 4px 6px;
        border-radius: 20px;
        transition: 0.25s;
        svg {
          * {
            fill: $white;
          }
        }
        &:nth-child(2) {
          right: 14px;
        }
        &:last-child {
          left: 14px;
        }
      }

      .img-holder {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: 0.35s ease-in-out;
        cursor: pointer;
        &:first-child {
          left: 0;
          width: 60%;
          z-index: 1;
          border-radius: 0 20px 20px 0;
          box-shadow: 2px 10px 10px 2px rgba($color: #000000, $alpha: 0.15);
        }
        &:nth-child(3) {
          right: 0;
          border-radius: 20px 0 0 20px;
          box-shadow: -2px 10px 10px 2px rgba($color: #000000, $alpha: 0.15);
          &:hover {
            // rotate: 90deg;
          }
        }
        &:hover {
          width: 85%;
          z-index: 1;

          & + .pos {
            opacity: 0;
          }
        }
        img {
          position: static !important;
          width: 100% !important;
          height: 100%;
          object-fit: cover;
          max-width: 1000%;
        }
      }
    }
  }

  .big {
    min-height: 488px;
    @media (max-width: 1201px) {
      min-height: 200px;
    }
    @media (max-width: 1000px) {
      height: 350px;
      .img {
        height: auto;
        object-position: 40% 100%;
      }
    }
  }
}
