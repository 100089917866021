.calculator {
  padding: 24px;
  h2 {
    margin-bottom: 24px;
  }

  .calc-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0 16px;

    .for-mob {
      display: none;
      pointer-events: none;
    }
    .left {
      padding: 32px 24px;
      border-radius: 20px;
      background: $light-wite;
      min-width: 550px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;

        .select {
          width: 100%;
          .plchldr {
            color: rgba($color: $grey, $alpha: 0.5);
          }
        }
        label,
        .label {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          color: $grey;
        }
        input,
        .select div {
          width: 100%;

          border-radius: 8px;
          border: 2px solid $accent-green;
          background: $white;
          padding: 8px 16px;

          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;

          outline: none;

          color: $grey;
        }

        .select {
          position: relative;
          outline: none;
          // z-index: 3;

          div {
            position: relative;
            cursor: pointer;
            // z-index: 3;
            &:after {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 7px 0 7px;
              border-color: $grey transparent $grey transparent;
              position: absolute;
              margin-top: 3px;
              margin-left: 10px;
              right: 13px;
              top: 50%;
              transform: translateY(-50%);
              transition: 0.25s;
            }
          }
          &:active,
          &:focus {
            div {
              &::after {
                // transform: translateY(-50%) rotate(180deg);
                border-color: $accent-green transparent $accent-green
                  transparent;

                border-width: 0px 7px 10px 7px;
              }
            }
          }
          ul {
            position: absolute;
            width: 100%;
            height: auto;
            top: calc(100% - 2px);
            left: 0;
            border: 2px solid $accent-green;
            background: $light-wite;
            border-radius: 8px;
            max-height: 300px;
            overflow-y: auto;
            cursor: pointer;
            overflow-x: hidden;
            z-index: 2;
            user-select: none;
            background: $white;
            z-index: 6;

            li {
              z-index: 5;
              cursor: pointer;
              font-size: 18px;
              transition: 0.25s;
              padding: 16px 32px;
              padding: 8px 16px;
              border-bottom: 1px solid rgba($color: $grey, $alpha: 0.14);
              &:last-child {
                border-bottom: 0px;
              }

              cursor: pointer;

              &:hover {
                background: $accent-green;
                color: $white;
              }
            }
          }
        }

        &.checkb {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .chkbx {
            position: relative;
            width: 32px;
            height: 32px;
            aspect-ratio: 1;
            border-radius: 8px;
            border: 2px solid $accent-green;
            background: $white;
            transition: 0.25s;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              opacity: 0;
              transition: 0.25s;
            }
            &.active {
              background: $accent-green;
              svg {
                opacity: 1;
              }
            }
          }
          .label {
            color: #000;
            text-align: center;
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            cursor: pointer;
          }

          &:hover {
            .chkbx {
              background: rgba($color: $accent-green, $alpha: 0.35);
              &.active {
                background: rgba($color: $accent-green, $alpha: 1);
              }
            }
          }
        }
      }
      .btn {
        margin-top: auto;
      }
    }
    .right {
      width: calc(100% - 550px);
      padding: 40px 24px 16px;
      background: $light-wite;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-radius: 20px;

      .text-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 8px;

        .text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          color: $grey;

          position: relative;

          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: rgba($color: #000000, $alpha: 0.09);
            transform: translateY(-50%);
          }

          span {
            background: $light-wite;
            // background: red;
            position: relative;
            display: block;
            z-index: 2;
            &:first-child {
              cursor: default;
            }
            &:last-child {
              font-weight: 700;
              letter-spacing: 2px;
              transition: 0.25s;

              &::after {
                height: 100%;
                width: 20px;
                content: '';
                position: absolute;
                right: 100%;
                top: 0;
                background: $light-wite;
              }
            }
          }

          &-pad {
            padding-left: 20px;
            // color: rgba(34, 34, 34, 0.7);
            color: rgba($color: $grey, $alpha: 0.7);
            span {
              position: relative;

              &:first-child {
                &::after {
                  height: 100%;
                  width: 20px;
                  content: '';
                  position: absolute;
                  right: 100%;
                  top: 0;
                  background: $light-wite;
                }
              }
            }
          }

          &:hover {
            span {
              &:last-child {
                scale: 1.5;
              }
            }
          }
        }

        .total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            width: 100%;
            height: 1px;
            background: $grey;
          }
          span {
            &:first-child {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
              color: $grey;
            }
            &:last-child {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
              color: $grey;
            }
          }
        }
      }

      .offer {
        margin-top: 16px;
        margin-top: auto;
        width: 100%;
        max-width: 100%;

        h3 {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          color: $grey;
        }
        .cars {
          width: 100%;
          display: flex;
          align-items: stretch;
          // justify-content: space-between;
          justify-content: flex-start;
          gap: 10px;
          max-width: 100%;
          overflow-x: auto;
          padding-bottom: 7px;
          user-select: none;

          &::-webkit-scrollbar {
            height: 4px;
          }
          &::-webkit-scrollbar-track {
            background: rgba($color: $grey, $alpha: 0.3);
            // border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: $accent-green;
            border-radius: 10px;
          }
          .car {
            width: 246px;
            min-width: 246px;
            height: 161px;
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 16px;
            transition: 0.25s;
            cursor: pointer;
            user-select: none;

            .img {
              object-fit: cover;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              pointer-events: none;
              img {
                object-fit: cover;
              }
            }
            .circle {
              position: absolute;
              width: 110%;
              height: auto;
              left: 50%;
              transform: translateX(-50%);
              top: calc(70%);
              border-radius: 50%;
              transition: 0.35s;
              aspect-ratio: 4/1;
              // background: linear-gradient(
              //     180deg,
              //     #3d8069 0%,
              //     #093d2c 100%
              // );
              background: $accent-green;
            }
            .title {
              transition: 0.4s;
              position: relative;
              z-index: 2;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 130%; /* 20.8px */
              text-transform: uppercase;
              font-family: 'Fixel-Bold';
              color: $white;
              // margin-top: auto;
              position: absolute;
              left: 16px;
              bottom: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: calc(100% - 16px - 16px);
              span {
                max-width: calc(100% - 20px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                transition: 0.25s;
                display: block;
              }

              svg {
                width: 16px;
                height: auto;

                transition: 0.25s;
                & {
                  * {
                    transition: 0.25s;

                    fill: $white;
                  }
                }
              }
            }

            &:hover {
              .title {
                bottom: 50%;
                transform: translateY(50%);
                span {
                  text-overflow: ellipsis;
                  white-space: normal;
                }
                svg {
                  width: 16px;
                  height: auto;

                  transform: rotate(45deg);
                }
              }
              .circle {
                aspect-ratio: 1;
                width: 300%;
                // height: 100%;
                top: 0;
                transform: translate(-50%, -50%);
                // border-radius: 0;
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1481px) {
  .calculator {
    .calc-container {
      .left {
        min-width: 400px;
        flex: 0 0 auto;
        // min-width: 0;
      }
      .right {
        flex: 1 0 0;
        min-width: 0;
        // width: calc(100% - 350px - 16px);
        width: auto;

        // .offer {
        //     .cars {
        //         gap: 10px;
        //         .car {
        //             flex: 1 0 0;
        //             min-width: 0;
        //             width: auto;
        //             height: auto;
        //             aspect-ratio: 16/9;
        //             // min-height: ;
        //             .circle {
        //                 width: 200%;
        //                 transform: translateX(-25%);
        //             }
        //         }
        //     }
        // }
      }
    }
  }
}

@media (max-width: 1201px) {
  .calculator {
    padding: 12px;
    .calc-container {
      .left {
        min-width: 300px;
        flex: 0 0 auto;
        // min-width: 0;
      }

      .right {
        flex: 1 0 0;
        min-width: 0;
        // width: calc(100% - 350px - 16px);
        width: auto;

        // .offer {
        //     .cars {
        //         gap: 10px;
        //         .car {
        //             .circle {
        //                 width: 250%;
        //                 transform: translateX(-25%);
        //             }
        //             .title {
        //                 font-size: 14px;
        //                 svg {
        //                     display: none;
        //                 }
        //             }
        //         }
        //     }
        // }
      }
    }
  }
}

@media (max-width: 1000px) {
  .calculator {
    padding-right: 0;
    padding-left: 0;
    .calc-container {
      flex-wrap: wrap;

      .for-mob {
        display: block;
        pointer-events: all;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .left {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 0;
        button {
          display: none;
        }
      }
      .right {
        width: 100%;
        border-radius: 0;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 0;
        .text-block {
          // padding: 40px 24px 16px;
          padding: 40px 24px 15px;
          gap: 12px;
          .text {
            span {
              &:first-child {
                max-width: 60%;
              }
            }
          }
        }
        .offer {
          h3 {
            padding-left: 24px;
            margin-bottom: 12px;
          }
          .cars {
            padding-left: 24px;
            padding-right: 24px;
            .car {
              &:hover {
                .title {
                  bottom: 8px;
                  transform: translateY(0);
                  span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  svg {
                    width: 16px;
                    height: auto;

                    transform: rotate(0);
                  }
                }
                .circle {
                  aspect-ratio: 4/1;
                  width: 110%;

                  // height: 100%;
                  top: calc(70%);
                  transform: translateX(-50%);
                  // border-radius: 0;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
