.admin-page {
  // background: red;
  // padding: 20px;
  // padding-top: 20px;
  .page {
    padding: 20px;
    @import './settings.scss';
  }
  @import './cars.scss';
  @import './enter.scss';

  @import './new-varik.scss';
  @import './chat.scss';
  @import './leads_bot.scss';

  .rews-container {
    max-width: 100%;
    width: 100%;
    padding: 15px;

    button {
      background: rgba(27, 105, 30, 1);
      // position: absolute;
      // right: 5px;
      // bottom: 5px;
      // width: 100px;
      padding: 6px 24px;
      min-width: 120px;
      margin-top: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 6px 8px;
      // width: 40px;
      // height: 40px;
      font-size: 16px;
      color: $white;
      cursor: pointer;
      text-transform: uppercase;
      // border-radius: 50%;
      transition: 0.25s;
      &:hover {
        background: darken($color: $accent-green, $amount: 6);
      }
      svg {
        // margin-left: 3px;
        margin-right: 0;
        // width: 25px;
        height: auto;
        * {
          fill: $white;
        }
      }
    }
    .rews {
      // background: #000;
      width: 100%;
      display: flex;
      align-items: stretch;

      max-width: 100%;
      display: flex;
      // flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 32px;

      .rew {
        border-radius: 6px;
        padding: 6px;
        flex: 1 0 0;
        min-width: 0;
        max-width: 268px;
        // height: 370px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        aspect-ratio: 3/4;
        background: rgba($color: #000000, $alpha: 0.25);
        position: relative;

        video {
          width: 100%;
          height: auto;
        }
        // background: red;
      }
    }
  }
}
