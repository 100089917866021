.def-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: $white;
  transition: 0.25s;
  background: rgba($color: $accent-green, $alpha: 1);
  border-radius: 20px;
  padding: 9.5px 20px;
  cursor: pointer;

  //     text-align: center;
  // font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  .icon {
    transition: 0.25s;
    * {
      transition: 0.25s;
      fill: $white;
    }
  }

  &:hover {
    background: rgba($color: $accent-green, $alpha: 0.75);
  }

  &.btn-reverse-items {
    flex-direction: row-reverse;
  }
  &.btn-fill {
    width: 100%;
  }
}

.btn-anim {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -70deg,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.8) 52%,
      rgba(255, 255, 255, 0) 56%
    );
    animation: shine 4s ease-in-out infinite;
    z-index: 1;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
    transition-property: left;
  }
}
