.process-delivery {
  padding: 48px 32px;
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }
  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 50px;
    @media (max-width: 1481px) {
      h2 {
        font-size: 36px;
      }
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 0;
      flex: 1 0 0;
      gap: 16px;
      @media (max-width: 1000px) {
        width: 100%;
      }
      &:last-child {
        // justify-content: flex-end;
        padding-top: 80px;
        min-width: 60%;
        // background: red;
        @media (max-width: 1000px) {
          // width: 100%;
          padding-top: 0;
        }
      }
      .process-slider {
        width: 100%;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 670px;
          margin-bottom: 24px;
          span {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
            letter-spacing: 0.72px;
            color: $grey;
            // margin-right: 120;
          }
          .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            @media (max-width: 1000px) {
              display: none;
            }
            .btn {
              // background: red;
              transition: 0.25s;
              cursor: pointer;
              border: 1px solid $accent-green;
              border-radius: 50%;
              &.left {
                transform: rotate(180deg);
              }
              svg {
                * {
                  transition: 0.25s;
                }
              }

              &:hover {
                scale: 1.3;
              }
              &.deactive {
                scale: 1;
                cursor: not-allowed;
                border: 1px solid rgba(34, 34, 34, 0.15);

                svg {
                  * {
                    // fill: $accent-green;
                    fill: rgba(34, 34, 34, 0.15);
                  }
                }
              }
            }
          }
        }

        .blocks {
          width: 100%;
          overflow-y: auto;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          gap: 0 8px;
          transition: 0.25s;
          scroll-behavior: smooth;

          // &::-webkit-scrollbar {
          //     height: 4px;
          // }
          // &::-webkit-scrollbar-track {
          //     background: rgba($color: $grey, $alpha: 0.3);
          //     // border-radius: 10px;
          // }

          // &::-webkit-scrollbar-thumb {
          //     background: $accent-green;
          //     border-radius: 10px;
          // }
          // padding-bottom: 5px;
          .block {
            border-radius: 20px;
            background: var(--grey-green, #e5efed);
            padding: 24px;
            min-width: 330px;
            min-height: 320px;
            // user-select: none;
            .title {
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%; /* 25.2px */
              letter-spacing: 0.72px;
              text-transform: uppercase;
              color: $grey;
              font-family: 'Fixel';
              margin-bottom: 8px;
            }
            p {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 22.4px */
              letter-spacing: 0.64px;
              color: $grey;
            }
          }
        }
      }
    }
  }
}
