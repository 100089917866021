@import './mixins.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

$main-bg: #f6f6f6;
$white: #fff;
$light-wite: #f6f6f6;
$accent-green: #00614a;
$green-90: #4a994d;
$green-100: #1b691e;
$green-110: #0c450e;
$sections-light: #f6f6f6;
$light-green: #8fff00;
$grey: #222;
$text-primary-black: #222;
$text-secondary: #222222b2;
$accent-green-gradient: linear-gradient(180deg, #3d8069 0%, #093d2c 100%);
$mobile-title-size: 40px;
$mobile-subtitle-size: 14px;
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --swiper-navigation-color: white;
}

@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-Light.ttf') format('truetype');
  font-weight: 200; // Black
  font-display: swap;
}
@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-Regular.ttf') format('truetype');
  font-weight: 400; // Normal
  font-display: swap;
}
@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-Medium.ttf') format('truetype');
  font-weight: 500; // Black
  font-display: swap;
}
@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600; // Black
  font-display: swap;
}
@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-Bold.ttf') format('truetype');
  font-weight: 700; // Bold
  font-display: swap;
}

@font-face {
  font-family: 'Fixel';
  src: url('./fonts/fixel/FixelDisplay-Black.ttf') format('truetype');
  font-weight: 900; // Black
  font-display: swap;
}

@font-face {
  font-family: 'Geologica';
  src: url('./fonts/geologica/Geologica-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica';
  src: url('./fonts/geologica/Geologica-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica';
  src: url('./fonts/geologica/Geologica-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  body {
    padding: 0;
    margin: 0;
    background: $main-bg;
    -webkit-font-smoothing: antialiased; /* Для WebKit-браузеров (Chrome, Safari) */
    -moz-osx-font-smoothing: grayscale; /* Для Firefox на macOS */
    text-rendering: optimizeLegibility;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba($color: $grey, $alpha: 0.3);
      // border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $accent-green;
      border-radius: 10px;
    }
    .no-scroll-bar {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        width: 0;
        height: 0;
      }
    }
    .main-container {
      max-width: 1920px;
      // padding: 0px 16px;
      margin: auto;
      @media (max-width: 760px) {
        padding: 0px;
      }
    }
    main {
      // padding: 16px 16px;
      position: relative;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    .full-container,
    .padd-container {
      // background: green;
      width: 100%;
      min-height: 100%;
      //
      max-width: 100%;
      // margin: 24px 0;
      margin-bottom: 24px;
    }
    .padd-container {
      padding: 0 16px;
      @media (max-width: $mobile-size) {
        padding: 0 10px;
      }
    }
    .white-bordered {
      overflow: hidden;
      border-radius: 20px;
      background: $white;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    .revert-overflow {
      overflow: revert;
    }

    h2 {
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 62.4px */
      text-transform: uppercase;
      font-family: 'Fixel-Bold';
      color: $grey;

      @media (max-width: 1000px) {
        font-size: 28px;
      }
    }

    @import './button.scss';

    @import './productsContainer.scss';

    @import './info/index.scss';
    @import './HeroPage/index.scss';
    @import './AboutPage/index.scss';
    @import './Contacts/index.scss';
    @import './Prices/index.scss';
    @import './AutoDelivery/index.scss';
    @import './Calculator.scss';
    @import './WeekOffer.scss';

    @import './layouts/index.scss';
    @import './Admin/index.scss';
    @import './shared/index.scss';
    @import './Catalog/index.scss';
    @import './Thanks/index.scss';
  }
  .not-find {
    width: 100%;
    height: 100vh;
    h2 {
      color: $accent-green;
      font-size: 47px;
      line-height: 100%;
    }
    p {
      letter-spacing: 1px;
      color: $grey;
      font-size: 21px;
      font-weight: 400;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      color: $white;
      transition: 0.25s;
      background: rgba($color: $accent-green, $alpha: 1);
      border-radius: 20px;
      padding: 9.5px 20px;
      cursor: pointer;
      margin-top: 20px;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;

      &:hover {
        background: rgba($color: $accent-green, $alpha: 0.75);
      }
    }
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-weight: 700;
}
