.advanteges {
  padding: 48px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media (max-width: 1201px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }
  .text-block {
    max-width: 615px;
    margin-right: auto;
    @media (max-width: 1481px) {
      max-width: 490px;
      margin-right: 30px;
    }
    @media (max-width: 1201px) {
      margin-bottom: 15px;
    }
    h2 {
      text-align: left;
      margin-bottom: 24px;
      @include h2-fixel-desk;
      @media (max-width: 1481px) {
        font-size: 36px;
      }
    }
    p {
      @include p2-geologica-desk;
      color: $grey;
      margin-bottom: 48px;
    }
    button {
      width: 232px;
      height: 53px;
      padding: 16px 32px 16px 32px;
      gap: 8px;
      border-radius: 40px;
      @include buttons-fixel-desk;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: end;
    gap: 18px 16px;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }

    .block {
      width: 352px;
      padding: 24px 17px 32px 24px;
      background: $light-wite;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      color: $grey;
      @media (max-width: 1481px) {
        width: 300px;
      }
      @media (max-width: 1201px) {
        width: 32%;
      }
      @media (max-width: 1000px) {
        width: 100%;
      }
      .title {
        @include h4-fixel-desk;
        margin-bottom: 24px;
        color: $green-100;
        text-transform: uppercase;
      }
      p {
        @include p3-geologica-desk;
      }
      .btn {
        margin-top: auto;
      }
      .icon {
        margin-bottom: 24px;
      }
    }
  }
}
