.hero-delivery {
  padding: 48px 32px;
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }
  h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 62.4px */
    text-transform: uppercase;
    font-family: 'Fixel';
    margin-bottom: 32px;
    color: $grey;
    @media (max-width: 1000px) {
      font-size: 34px;
    }
  }

  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .column {
      min-width: 0;
      flex: 1 0 0;
      padding: 24px;
      border-radius: 20px;
      background: rgba(0, 97, 74, 0.05);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 1000px) {
        width: 100%;
      }
      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 41.6px */
        text-transform: uppercase;
        font-family: 'Fixel';
        margin-bottom: 16px;
        color: $grey;
        @media (max-width: 1000px) {
          font-size: 28px;
        }
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.72px;
        color: $grey;
        margin-bottom: 16px;
      }
      .btn {
        margin-top: auto;
      }
    }
  }
}

.auctions-delivery {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 32px;
  padding-bottom: 48px;
  padding-top: 0;
  @media (max-width: 1201px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }
  h2 {
    text-align: left;
    margin-right: auto;
    @media (max-width: 1201px) {
      width: 100%;
    }
  }

  .block {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
    background: $light-wite;
    max-width: 349px;
    @media (max-width: 1201px) {
      min-width: 0;
      flex: 1 0 0;
    }
    @media (max-width: 1000px) {
      flex: 1 0 auto;
      width: 100%;
      max-width: 100%;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $accent-green;
      border-radius: 5px;
      width: 48px;
      height: 48px;
      aspect-ratio: 1;
      margin-bottom: 24px;
    }
    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: $grey;
      font-family: 'Fixel';
      margin-bottom: 8px;
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.64px;
        color: $grey;
      }
    }
  }
}
