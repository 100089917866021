@import '../mixins.scss';
.service-block {
  .padd-container {
    .white-bordered {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 48px 32px;
      gap: 80px;

      .left {
        width: 50%;
        max-width: 709px;
        margin-right: auto;

        h2 {
          @include h2-fixel-desk;
          text-align: left;
          color: $text-primary-black;
          margin-bottom: 24px;
        }

        p {
          color: $text-secondary;
          @include p3-geologica-desk;
          text-align: left;
          margin-bottom: 48px;
        }

        button {
          padding: 16px 32px 16px 32px;
          background-color: $green-100;
          color: #fff;
          border: none;
          border-radius: 40px;
          font-size: 18px;
          font-family: 'Fixel';
          cursor: pointer;
        }
      }
      .right {
        // flex: 1 0 0;
        width: 50%;

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: end;
        gap: 21px;

        .service-block__item {
          width: 50%;
          display: flex;
          max-width: 420px;
          flex: 1 0 50%;
          padding: 24px;
          border-radius: 20px;
          @include background;
          height: 167px;
          .service-block__item__text {
            &__title {
              @include h4-fixel-desk;
              text-align: left;
              color: #fff;
              margin-bottom: 16px;
              text-transform: uppercase;
            }

            &__text {
              @include p3-geologica-desk;
              text-align: left;
              color: #fff;
              width: 350px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .service-block {
    .padd-container {
      .white-bordered {
        position: relative;
        padding: 24px 12px 100px 12px;
        flex-direction: column;
        gap: 30px;

        .left {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h2 {
            text-align: left;
            width: 100%;
          }
          p {
            text-align: left;
            width: 100%;
            margin-bottom: 0px;
          }

          button {
            width: 155px;
            height: 44px;
            padding: 14px 28px 14px 28px;
            gap: 8px;
            position: absolute;
            bottom: 24px;
            left: 12px;
            @include buttons-fixel-mobile;
            background-color: $green-100;
          }
        }

        .right {
          width: 100%;
          justify-content: start;
          .service-block__item {
            flex-direction: column;
            gap: 12px;
            padding: 16px;
            height: 201px;

            .service-block__item__text {
              .service-block__item__text__title {
                // text-align: center;
              }
              .service-block__item__text__text {
                // text-align: l;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
