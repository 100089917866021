.menu {
  width: 446px;
  flex: 0 0 446px;
  margin-right: 24px;
  @media (max-width: $mobile-size) {
    width: 100%;
    flex: 0 0 100%;
    margin-right: unset;
  }
  &__item {
    width: 100%;
    margin-bottom: 16px;
  }
  &__button {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    padding: 12px 0px 12px 24px;
    // gap: 10px;
    border-radius: 12px;
    background-color: white;
    text-align: left;
    color: black;
    &-hero {
      @include h4-fixel-desk;
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 26px;
        top: 50%;
        width: 12px;
        height: 12px;
        border-left: 1.75px solid $green-100;
        border-bottom: 1.75px solid $green-100;
        transform: rotate(-45deg) translateY(-50%);
      }
    }

    &_select {
      background-color: $green-90;
      color: white;
      margin-bottom: 12px;
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 26px;
        top: 50%;
        width: 12px;
        height: 12px;
        border-left: 1.75px solid $white;
        border-bottom: 1.75px solid $white;
        transform: rotate(135deg) translate(50%, 20%);
      }
    }

    &-mark {
      margin-bottom: 8px;
      transition: all 0.3s ease;
      @include p2-geologica-desk;
    }

    &-green {
      color: #1b691e;
    }

    &-submenu_select {
      color: $light-green;
    }
  }
}

@media (max-width: $mobile-size) {
  .submenu__mob-menu {
    max-height: 260px;
    overflow-y: scroll;
    @include p3-geologica-mobile;
    border-radius: 6px;
    .menu__button-mark {
      padding: 8px 10px;
      margin-bottom: unset;
      border-radius: unset;
    }
  }
}
