@import '../mixins.scss';

.information-layout {
  padding-top: 48px;
  @media (max-width: $desk-hd) {
    padding-top: 24px;
  }
}

.information {
  .info {
    padding: 48px 24px;
    color: $grey;
    @media (max-width: 1000px) {
      padding: 24px 10px;
    }
  }
  .feedback-slug {
    width: 480px;
    height: 320px;
    border-radius: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rews_block__content {
    max-width: 100%;
    display: flex;
    // flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 32px;

    .rew {
      flex: 1 0 0;
      width: 208px;
      height: 370px;

      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 3/4;
      position: relative;
      border-radius: 16px;
      overflow: hidden;

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: 0.25s;
      }
      &::after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #000000, $alpha: 0.25);
        transition: 0.25s;
        border-radius: 16px;
      }

      &.playd {
        .play {
          opacity: 0;
        }
        &::after {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
  h1 {
    @include h2-fixel-desk;
    text-transform: uppercase;
    color: black;
    margin-bottom: 16px;
    font-family: 'Fixel';
    text-align: left;
    @media (max-width: 1000px) {
      font-size: 36px;
    }
  }
  h3 {
    @include h3-fixel-desk;
    text-transform: uppercase;
    color: $grey;
    margin: 16px 0;
    text-align: left;
  }
  h2 {
    margin-bottom: 24px;
    text-align: left;
    @include h2-fixel-desk;
  }

  .video-container {
    // max-width: calc(100% - 390px);
    margin-bottom: 20px;
    max-width: 100%;
    overflow: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 25px;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba($color: $grey, $alpha: 0.3);
      // border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $accent-green;
      border-radius: 10px;
    }
    .video {
      min-width: 300px;
      // min-height: 450px;
      aspect-ratio: 9/16;
      margin-bottom: 30px;
      position: relative;
      user-select: none;
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &[poster] {
          background-size: cover;
        }
      }
      .play {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        pointer-events: none;
        opacity: 0;
        transition: 0.25s;
        z-index: 2;

        &.active {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
  .rew-block {
    width: 100%;
    border-radius: 20px;
    padding: 32px;
    background: #e5efed;
    svg {
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.72px;
      margin-bottom: 32px;
    }
    .who {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-right: 18px;
        img {
          object-fit: cover;
        }
        span {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px;
        }
      }
    }
  }

  .block-text {
    width: 100%;
    border-radius: 20px;
    background: $light-wite;
    padding: 24px;

    @include p3-geologica-desk;
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 1000px) {
      padding: 12px;
    }

    p {
      margin: 9px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin: 5px;
      padding-left: 22px;
    }
  }
}

@media (max-width: $mobile-size) {
  .rews_block__content {
    .rew {
      height: 370px;
      width: 100%;
      flex: 1 0 100%;
      max-width: 100%;
      &::after {
        background: transparent;
      }

      video {
        height: 100%;
      }
    }
  }

  .rews_block__feedback {
    height: 230px;
    .swiper-slide {
      height: 230px !important;
      .feedback-slug {
        height: 230px;
        width: 320px !important;
      }
    }
  }
}
