.achievements {
  .padd-container {
    .white-bordered {
      padding: 48px 32px;
    }
  }

  text-align: center;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include h2-fixel-desk;
    max-width: 835px;
    text-transform: uppercase;
    margin-bottom: 32px;
    @media (max-width: $mobile-size) {
      text-align: left;
    }
  }

  .content {
    width: 100%;
    &__title {
      @include h3-fixel-desk;
      text-align: center;
      margin-bottom: 32px;
      text-transform: uppercase;
      @media (max-width: $mobile-size) {
        text-align: left;
      }
    }
    &__card-block {
      display: flex;
      gap: 24px;
    }
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .card {
    background: #f6f6f6;
    padding: 24px 24px 32px 24px;
    border-radius: 24px;
    width: 33.33333%;

    &__title {
      @include h4-fixel-desk;
      text-align: left;
      color: $green-100;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    &__text-item {
      color: $text-primary-black;
      @include p3-geologica-desk;
      text-align: left;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: $mobile-size) {
  .achievements {
    .padd-container {
      .white-bordered {
        padding: 24px 12px;
      }
    }
  }
  .content__card-block {
    flex-wrap: wrap;
  }

  .card {
    width: 100% !important;
  }
}
