.faq-delivery {
  padding: 48px 32px;
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }
  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 1000px) {
      width: 100%;
    }
    h2 {
      margin-bottom: 40px;
      @media (max-width: 1481px) {
        font-size: 36px;
      }
      @media (max-width: 1000px) {
        // font-size: 28px;
        margin-bottom: 15px;
      }
    }
    .img {
      width: 100%;
      height: auto;
      min-height: 566px;
      position: relative;
      border-radius: 20px;
      height: 100%;
      overflow: hidden;
      img {
        object-fit: cover;
      }

      @media (max-width: 1000px) {
        min-height: 200px;
      }
    }
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.72px;
      color: $grey;
      margin-bottom: 40px;
    }

    .faq-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 32px;
      color: $grey;
      .faq {
        width: 100%;
        border-radius: 20px;
        border-bottom: 1px solid $grey;
        // padding: 16px 24px;
        transition: 0.25s;
        overflow: hidden;

        .head {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 28px */
          letter-spacing: 0.8px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          transition: 0.25s;

          padding: 16px 24px;

          .icon {
            transition: 0.25s;
            position: relative;
            transform: rotate(90deg);
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              // background: red;
            }
          }
          &:hover {
            background: rgba($color: $accent-green, $alpha: 0.25);
          }
        }
        .faq-content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.64px;
          transition: 0.45s ease-out;

          overflow-y: hidden;
          max-height: 0px;
          padding: 0 24px;
        }

        &.active {
          .head {
            .icon {
              transform: rotate(-90deg);
            }
          }
          .faq-content {
            // padding-top: 24px;
            // padding-bottom: 8px;
            padding: 24px;
            max-height: 300px;
            @media (max-width: 1000px) {
              max-height: 600px;
            }
          }
        }
      }
    }
    &:first-child {
      width: 40%;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    &:last-child {
      width: 60%;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }
}
