.tags {
  gap: 16px;
  border-radius: 24px;

  &__body {
    padding: 24px 8px 24px 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      color: $white;
      background-color: $green-90;
      margin-right: 16px;
      padding: 16px 24px 16px 24px;
      gap: 10px;
      border-radius: 12px;
      font-family: Fixel;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      text-align: center;

      &:last-child {
        margin-right: 0px;
      }

      &:hover {
        background-color: $green-100;
      }
    }

    // button {
    // }
  }
}

@media (max-width: 1562px) {
  .tags {
    max-height: unset;
    .tags__body {
      row-gap: 16px;
    }
  }
}

@media (max-width: 1000px) {
  .tags {
    &__body {
      padding: 24px 12px;
      flex-wrap: wrap;
      justify-content: left;
      row-gap: 8px;
      a {
        @include buttons-fixel-mobile;
        padding: 12px 16px;
        background-color: $green-90;
        margin-bottom: unset;
      }
    }
  }
}
