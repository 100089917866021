.interesting {
  padding: 48px 32px 32px 32px;

  @include background;
  background-position: right;
  width: 100%;
  @media (max-width: 1481px) {
    padding: 36px;
  }
  @media (max-width: 1000px) {
    padding: 24px 8px;
  }
  h2 {
    color: $white;
    margin-bottom: 48px;
    @media (max-width: 1000px) {
      margin-bottom: 24px;
    }
    @include h2-fixel-desk;
  }

  .blocks {
    background: $light-wite;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 48px;
    @media (max-width: 1000px) {
      padding: 24px 12px;
    }
    .block {
      width: 348px;
      min-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-left: 1px solid #1b691e;
      padding: 0px 24px;
      flex: 1 1 auto;
      @media (max-width: 1000px) {
        padding: unset;
        padding-bottom: 32px;
        border-left: unset;
        border-bottom: 1px solid #1b691e;
        margin-bottom: 32px;
        &:last-child {
          border-bottom: unset;
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }
      &:first-child {
        border-left: unset;
      }
      .icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: $green-100;
        margin-bottom: 48px;
        @media (max-width: 1201px) {
          margin-bottom: 24px;
          margin-right: 20px;
        }
      }

      .cont {
        position: relative;
        width: 100%;
        height: 100%;
        .title {
          @include h4-fixel-desk;
          text-transform: uppercase;
          color: $accent-green;
          margin-bottom: 16px;
          @media (max-width: 1201px) {
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.64px;
          color: $grey;
          @include p3-geologica-desk;
        }
      }
    }
  }
}
