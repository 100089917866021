.new-varik {
  width: 100%;
  padding: 15px;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99;

    .dots-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    .dot {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #b3d4fc;
      animation: pulse 1.5s infinite ease-in-out;
    }

    .dot:last-child {
      margin-right: 0;
    }

    .dot:nth-child(1) {
      animation-delay: -0.3s;
    }

    .dot:nth-child(2) {
      animation-delay: -0.1s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.1s;
    }

    .lds-ellipsis {
      // display: inline-block;
      // position: relative;
      // width: 80px;
      // height: 80px;
      // border-radius: 50%;

      // background: linear-gradient(90deg, #f5f5f5, #e5e5e5, #f5f5f5);
      // // border: 8px solid rgba($color: #fff, $alpha: 0.75);
      // // border-top: transparent;
      // // border-right: transparent;
      // overflow: hidden;
      // &:after {
      //     content: '';
      //     display: block;
      //     position: absolute;
      //     width: 100%;
      //     height: 8px;

      // }
    }
  }
  .add-layout {
    // display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba($color: #000000, $alpha: 0.33);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.25s;
    opacity: 0;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: all;
    }
    .content-container {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      .img {
        width: 100%;
        background: #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 185px;
        transition: 0.25s;
        cursor: pointer;
        box-shadow: 0 -3px 12px 6px rgba($color: #000000, $alpha: 0.182);
        position: relative;

        svg {
          width: 55px;
          height: auto;
          filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.1));
        }
        input {
          opacity: 0;
          position: absolute;
          // z-index: -1;
          pointer-events: none;
        }
        // img {
        // }
        &:hover {
          opacity: 0.5;
        }
      }

      .inputs-container {
        padding: 15px;
        max-height: 350px;
        overflow-y: auto;
        margin-bottom: 4px;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: rgba($color: $grey, $alpha: 0.3);
          // border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $accent-green;
          border-radius: 10px;
        }

        .inputs {
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          margin-bottom: 8px;

          label {
            flex: 0 1 auto;
            background: #ddd;
            padding: 4px 6px;
            border-radius: 4px 0 0 4px;
            transition: 0.25s;
            border: 1px solid transparent;
            border-right: 0;
            order: 1;
            cursor: pointer;
          }

          input,
          select {
            order: 2;

            flex: 1 0 0;
            transition: 0.25s;

            border: 1px solid #ddd;
            border-left: 0;
            border-radius: 0 4px 4px 0;
            // width: 100%;
            background: #fff;
            padding: 4px 6px;
            outline: none;
            &:active,
            &:focus {
              border-color: $accent-green;

              & + label {
                border-color: $accent-green;
              }
            }
          }
        }
      }
      .btn {
        width: 100%;
        padding: 15px;
        padding-top: 0;
        button {
          width: 100%;
        }
      }
    }
  }

  button {
    border-radius: 6px;
    background: lighten($color: $accent-green, $amount: 3);
    &:hover {
      background: rgba($color: $accent-green, $alpha: 0.65);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
