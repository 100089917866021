.big-contact-form {
  .big-contact-form-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 800px;

    .img {
      position: relative;
      width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .form {
      width: 50%;
      padding: 48px;
      display: flex;
      flex-direction: column;
      font-family: 'Fixel';
      h2 {
        max-width: 740px;
        @include h2-fixel-desk;
        text-transform: uppercase;
        color: $text-primary-black;
        text-align: left;
        margin-bottom: 12px;
      }
      p {
        color: $text-primary-black;
        @include p2-geologica-desk;
        text-align: left;
        margin-bottom: 48px;
      }

      .form-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 24px;
        .column {
          width: calc(50% - 12px);
          &:last-child {
            width: 100%;
          }

          input,
          textarea,
          .select div {
            width: 100%;
            max-height: 53px;

            border-radius: 16px;
            border: 1px solid $green-90;
            background: $sections-light;
            padding: 16px 24px;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.8px;

            outline: none;
            color: $text-primary-black;
            &::placeholder {
              color: $text-primary-black;
            }
          }
          textarea {
            resize: none;
            min-height: 100px;
          }

          .select {
            position: relative;
            outline: none;

            div {
              position: relative;

              &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 7px 0 7px;
                border-color: $grey transparent $grey transparent;
                position: absolute;
                margin-top: 3px;
                margin-left: 10px;
                right: 13px;
                top: 50%;
                transform: translateY(-50%);
                transition: 0.25s;
              }
            }
            &:active,
            &:focus {
              div {
                &::after {
                  // transform: translateY(-50%) rotate(180deg);
                  border-color: $accent-green transparent $accent-green
                    transparent;

                  border-width: 0px 7px 10px 7px;
                }
              }
            }
            ul {
              position: absolute;
              width: 100%;
              height: auto;
              top: calc(100% - 2px);
              left: 0;
              border: 2px solid $accent-green;
              background: $light-wite;
              border-radius: 8px;
              max-height: 300px;
              overflow-y: auto;
              cursor: pointer;
              overflow-x: hidden;
              user-select: none;

              li {
                cursor: pointer;
                font-size: 16px;
                transition: 0.25s;
                padding: 16px 32px;
                cursor: pointer;
                border-bottom: 1px solid rgba($color: $grey, $alpha: 0.14);
                &:last-child {
                  border-bottom: 0px;
                }

                &:hover {
                  background: $accent-green;
                  color: $white;
                }
              }
            }
          }
          .select-list {
            z-index: 2;
            position: relative;
          }

          .flag-dropdown {
            border-radius: 30px 0px 0px 30px;
            border: unset;
            background: unset;
          }

          .selected-flag {
            background: unset;
            padding-left: 16px;
            &:hover {
              background: unset;
            }
          }

          .form-control {
            padding-left: 46px;
            height: 53px;
          }
        }
      }

      .rules {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          font-family: Geologica;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.4px;
          color: $text-primary-black;
          a {
            font-weight: 500;
            // text-decoration: underline;
            color: $text-secondary;
            transition: 0.25s;
            margin-left: 4px;

            &:hover {
              color: rgba($color: $accent-green, $alpha: 0.7);
            }
          }
        }
        button {
          border-radius: 40px;
          background-color: $green-100;
          padding: 12px 32px;
          font-family: Fixel;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
        }
      }

      .btm-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 17px;
        margin-top: auto;
        .btn {
          min-width: 0;
          flex: 1 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;

          border-radius: 24px;
          background: $sections-light;
          height: 72px;
          cursor: pointer;
          transition: 0.25s;

          span {
            margin: 0 16px 0 8px;
            transition: 0.25s;
            font-family: Fixel;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
          }
          svg {
            * {
              transition: 0.25s;
            }
            &:last-child {
              width: 16px;
              height: 16px;
            }
          }

          &:hover {
            background: $accent-green;

            span {
              color: $white;
            }
            svg {
              * {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1301px) {
  .big-contact-form {
    .big-contact-form-container {
      .form {
        .btm-btns {
          .btn {
            padding: 0 5px;
            span {
              text-align: center;
              font-size: 15px;
              margin: 0 9px 0 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1141px) {
  .big-contact-form {
    .big-contact-form-container {
      .form {
        width: 60%;
        padding: 32px;
        .btm-btns {
          .btn {
            padding: 0 5px;
            span {
              text-align: center;
              font-size: 15px;
              margin: 0 0px 0 5px;
            }
            svg {
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .big-contact-form {
    .big-contact-form-container {
      min-height: 0;
      flex-wrap: wrap;
      .img {
        width: 100%;
        padding-top: 45%;
        img {
          object-position: bottom;
        }
      }
      .form {
        width: 100%;
        padding: 24px 12px;
        margin-top: -16px;
        border-radius: 16px;
        background-color: #fff;
        z-index: 1;

        p {
          margin-bottom: 32px;
        }

        .form-container {
          .column {
            width: 100%;
            input,
            textarea {
              padding: 16px 12px;
              height: 36px !important;
              &::placeholder {
                @include buttons-fixel-mobile;
              }
            }
            textarea {
              height: 100px;
            }
            .select {
              input {
              }
              div {
                height: 36px;
                padding: 9px 10px;
                @include buttons-fixel-mobile;
                &::placeholder {
                  @include buttons-fixel-mobile;
                }
              }
            }
          }
        }
        .rules {
          flex-wrap: wrap;
          gap: 12px;
          margin-bottom: 10px;
          .text {
            width: 100%;
            margin-top: -20px;
          }
          .btn {
            width: 50%;
            margin-top: 15px;
            margin-bottom: 20px;
            @include buttons-fixel-mobile;
          }
        }

        .btm-btns {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 15px;
          margin-top: 20px;
          .btn {
            width: 100%;
            flex: 1 0 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 0 25px;
            height: 53px;
            svg {
              &:first-child {
                margin-left: auto;
              }
              &:last-child {
                margin-left: auto;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
