@import '../mixins.scss';

.hero-block {
  height: 100dvh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  @media (max-width: $mobile-size) {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  overflow: hidden;

  font-size: 16px;

  .bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      filter: brightness(0.4);
    }
  }

  .hero-block__content {
    position: relative;
    z-index: 2;
    width: 100%;

    padding-left: 130px;

    color: white;
    .forms {
      margin-top: 40px;

      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;

      gap: 16px;

      button {
        padding: 16px 20px;
        border-radius: 40px;
        color: $text-primary-black;
        background: $light-green;
        transition: 0.35s;
        font-family: Fixel;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;
        text-align: center;

        &:hover {
          background: rgba(143, 255, 0, 0.8);
        }
      }
      input {
        width: 300px;
        height: 50px;
        padding: 8px 24px;
        border: none;
        border-radius: 30px;
        font-size: 1.5em;
        font-family: 'Fixel';
        color: #222222;
        background: white;

        font-size: 16px;
        font-weight: 400;
        line-height: 100%;

        outline: none;

        // box-shadow: 0 0 10px rgba(0,0,0,0.1);
        transition: 0.3s;
        &:focus {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
        &::placeholder {
          font-family: Fixel;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.8px;
          color: $text-primary-black;
        }
      }
    }
    // background: red;
  }

  &__title-h1 {
    @include h2-fixel-desk;
    line-height: 1.1;
    font-size: 100px;
    text-align: left;
    text-transform: uppercase;
  }

  &__title-h2 {
    @include h3-fixel-desk;
    font-size: 42px;
    text-align: left;
    text-transform: uppercase;
    color: white;
    margin-bottom: 6px;
  }
  &__title-h3 {
    @include h4-fixel-desk;
    font-size: 36px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    @include p3-geologica-desk;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 48px;
    p {
      margin-right: 24px;
    }
  }
}

@media (max-width: $mobile-size) {
  .hero-block {
    .hero-block__content {
      padding-left: 0;
      color: white;
      padding: 0px 24px 0px 10px;
      transform: translateY(18px);
      h1 {
        font-size: $mobile-title-size;
        margin-bottom: 12px;
        text-align: left;
        line-height: 48px;
        font-weight: 700;
      }
      .hero-block__subtitle {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        // width: 240px;
        p {
          font-size: $mobile-subtitle-size;
          margin-bottom: 16px;
          margin-right: unset;
          font-weight: 600;
        }
        span {
          margin-bottom: 32px;
          width: 122px;
          height: 48px;
          padding: 12px 20px 12px 20px;
          gap: 8px;
          border-radius: 100px;
          background-color: #222222;
          font-family: Fixel;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
        }
        margin-bottom: unset;
      }
      .forms {
        margin-top: 30px;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: auto;
        gap: 12px;
        button {
          width: 176px;
          height: 44px;
          padding: 14px 28px 14px 28px;
          gap: 8px;
          border-radius: 40px;
          font-family: Fixel;
          font-size: 12px;
          font-weight: 600;
          line-height: 15.6px;
          text-align: center;
          background: #8fff00;
          margin-top: 12px;
        }
        input {
          height: 36px;
          width: 222px;
          height: 36px;
          padding: 10px 20px 10px 20px;
          gap: 10px;
          border-radius: 21px;
          &::placeholder {
            font-family: Fixel;
            font-size: 12px;
            font-weight: 600;
            line-height: 15.6px;
            text-align: left;
          }
        }
      }
    }
    img {
      object-position: 25%;
      object-fit: 100px !important;
    }
  }
}
