$desk-hd: 1280px;

.prices-bg {
  min-height: 100dvh;
  border-radius: 0px 0px 20px 20px;
  position: relative;
  background: no-repeat center/cover
    url('/src/imgs/nashi_tseny/bg_nashi_tseny.jpg');
  margin-bottom: 48px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: brightness(0.4);
    border-radius: 0px 0px 20px 20px;
  }
}
.prices-includes {
  padding: 48px 32px;
  max-width: 1120px;
  position: absolute;
  left: 120px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @media (max-width: $desk-hd) {
    padding: 24px 12px;
    padding-top: 108px;
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 48px;
    @include h1-fixel-desk;
    color: $white;
  }

  .tags {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    max-height: unset;

    .tag {
      position: relative;
      color: $white;
      @include buttons-fixel-desk;
      padding: 12px 20px 12px 36px;
      border-radius: 20px;
      background: #ffffff80;
      &::after {
        content: '';
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: #222222;
        border-radius: 50%;
        @media (max-width: $mobile-size) {
          left: 12px;
        }
      }
      @media (max-width: $mobile-size) {
        padding: 8px 12px 8px 28px;
        @include captian2-geologica-mobile;
      }
    }
  }
}

.prices-block {
  padding: 48px 32px;
  @media (max-width: $mobile-size) {
    padding: 24px 12px;
  }
  h2 {
    text-align: left;
    margin-bottom: 32px;
    @include h3-fixel-desk;
  }

  .blocks {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    @media (max-width: $mobile-size) {
      gap: 24px;
    }
  }

  .block {
    padding: 24px 29px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include background;
    border-radius: 20px;
    flex: 0 0 calc(25% - 24px);
    @media (max-width: $desk-hd) {
      flex: 0 0 calc(33.333% - 24px);
    }
    @media (max-width: $mobile-size) {
      flex: unset;
    }
    .price {
      @include h4-fixel-desk;
      text-transform: uppercase;
      color: $light-green;
      margin-bottom: 16px;
    }
    .title {
      @include h4-fixel-desk;
      text-transform: uppercase;
      margin-bottom: 8px;
      color: $white;
      @media (max-width: 1201px) {
        font-size: 16px;
      }
    }
    p {
      @include p3-geologica-desk;
      color: $white;
      max-width: 311px;
      .accent {
        font-weight: 600;
        color: $light-green;
      }
      @media (max-width: 1201px) {
        font-size: 14px;
      }
    }
  }
  img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
