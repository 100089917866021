.with-side-bar {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  .side-pick {
    flex: 0 0 568px;
    @media (max-width: $desk-hd) {
      flex: unset;
    }
  }

  .sidebar {
    padding: 24px;
    height: auto;
    width: 568px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: $desk-hd) {
      // background: $main-bg;
      width: 100%;
      padding: 24px 10px;
    }

    a {
      @include p1-geologica-desk;
      padding: 16px;
      padding-left: 11px;
      width: 100%;
      display: block;
      color: $text-primary-black;
      border-left: 5px solid transparent;
      transition: 0.25s;
      border-radius: 16px;

      &:hover {
        // border-color: $accent-green;
        background: #e5efed;
      }
      &.active {
        background-color: $green-100;
        color: $white;
      }
      @media (max-width: $desk-hd) {
        margin-bottom: 4px;
      }
    }
  }
  .side-out-content {
    width: calc(100% - 420px);
    min-width: 420px;
    // width: 100%;
    flex: 1 0;
    // height: 500vh;
    @media (max-width: 1000px) {
      width: 100%;
      min-width: 0;
      // background: #e5efed;
    }
  }
}
