@import '../mixins';

.youtubs_block {
  margin-top: 24px;

  .white-bordered {
    position: relative;
    padding: 48px 32px;
    font-family: Geologica;

    .bg {
      img {
        filter: brightness(0.4);
      }
    }
  }

  .youtubs_block__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    position: relative;
    z-index: 1;

    h2 {
      @include h2-fixel-desk;
      color: #fff;
      padding: 0;
      text-align: left;
    }

    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 64px;

      p {
        max-width: 300px;
        color: #ffff;
        @include p3-geologica-desk;
        text-align: right;
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        a {
          width: 60px;
          height: 60px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.2);

          svg {
            max-width: 24px;
            height: auto;
          }
        }
      }
    }
  }

  .youtubs_block__content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 34px;
    position: relative;
    z-index: 1;

    .youtubs_block__item {
      flex: 1 0 0;
      min-width: 0;

      iframe {
        // background: red;
        border-radius: 20px;
        overflow: hidden;
        aspect-ratio: 16 / 9;
        margin-bottom: 24px;
        width: 100%;
        height: auto;
      }

      .v_title {
        color: #fff;
        @include p2-geologica-desk;
        text-align: left;
      }
    }
  }
}

.lazy-load-youtube__container {
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 16/9;
  margin-bottom: 24px;
  width: 100%;
  height: auto;
  border: 0 solid #e5e7eb;
  background: #1c1b22;
}

@media (max-width: 1000px) {
  .youtubs_block {
    .white-bordered {
      padding: 21px 16px;

      .youtubs_block__title {
        flex-direction: column;

        .block {
          gap: 16px;
          flex-direction: column;
          margin-top: 12px;
          align-items: start;
          width: 100%;

          p {
            text-align: left;
          }

          .btns {
            a {
              width: 50px;
              height: 50px;
            }
          }
        }
      }

      .youtubs_block__content {
        flex-direction: column;
      }
    }
  }

  .lazy-load-youtube__container {
    margin-bottom: 12px;
  }
}
