.modal-contact {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba($color: #000000, $alpha: 0.4);
  // backdrop-filter: blur(8px);
  z-index: 1000;
  .closeLayer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    backdrop-filter: blur(8px);
    transition: 0.25s;
    cursor: pointer;

    &:hover {
      // backdrop-filter: blur(6px);
      background: rgba($color: #000000, $alpha: 0.2);
    }
    // background: red;
  }
  .modal-conainer {
    background: $white;
    max-width: 100%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    // width: 500px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 240px;
    @media (max-width: 1000px) {
      border-radius: 8px;
    }
    .img {
      // width: 55%;
      width: 250px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
    .right {
      // width: 45%;
      min-width: 300px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 1000px) {
        width: 90%;
        max-width: 300px;
      }
      h2 {
        font-size: 23px;
      }
      p {
        text-align: center;
      }
      .form {
        margin: 15px;
        margin-bottom: 0;
        margin-top: auto;
        .inputs {
          width: 100%;
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 10px;
          margin-top: 25px;
          input {
            box-sizing: border-box;
            border: 2px solid transparent;
            padding: 16px 16px;
            min-width: 250px;
            background: $white;
            border-radius: 40px;
            outline: none;
            transition: 0.25s;
            min-height: 40px;
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            color: $grey;
            line-height: 21px;
            width: 100%;
            max-width: calc(150px + 190px + 16px + 16px);
            border-color: rgba($color: $accent-green, $alpha: 1);
            &:hover {
              border-color: rgba($color: $accent-green, $alpha: 0.4);
            }
            &:focus {
              border-color: $accent-green;
            }
            @media (max-width: 1000px) {
              margin-bottom: 10px;
              padding: 16px;
              border-radius: 12px;
            }
          }
          .form-control {
            padding-left: 40px;
            min-height: 40px;
            height: 57px;
          }
          .flag-dropdown {
            background: unset;
            border: unset;
          }
          .selected-flag {
            &:hover {
              background: unset;
            }
          }

          // .country-list {
          //     z-index: ;
          // }
          .btn {
            // position: absolute;
            right: 10px;
            top: 50%;
            width: 100%;
            // transform: translateY(-50%);
            @media (max-width: 1000px) {
              position: static;
              transform: translateY(0%);
              width: 100%;
            }
          }
        }
      }
    }
  }
}
