@import '../mixins.scss';

.etaps {
  padding: 48px 32px;
  @include background;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1141px) {
    display: none;
  }

  h2 {
    margin-bottom: 32px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Fixel';

    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
    position: relative;
    z-index: 1;
    @include h1-fixel-desk;
  }
  .etaps-container {
    position: relative;
    z-index: 1;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    color: #ffff;
    position: relative;
    margin-bottom: 24px;
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 24px - 24px);
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      height: 1px;
      background: rgba(143, 255, 0, 1);
    }

    .etap {
      color: $grey;
      position: relative;
      z-index: 2;
      flex: 1 0 0;
      height: 200px;
      transition: 0.25s;
      color: #ffff;
      img {
        transform: scaleX(-1);
      }
      .title {
        position: absolute;
        left: 8px;
        text-align: center;

        white-space: nowrap;
        hyphens: none;
        width: auto;
        transform: translateX(-50%);

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.25s;

        pointer-events: none;

        .desc {
          transition: 0.75s;
          overflow: hidden;
          max-height: 0px;
          white-space: normal;
          height: auto;
          font-size: 14px;
          width: 250px;
        }
      }

      &:first-child {
        .title {
          .desc {
            left: 0;
            text-align: left;

            transform: translateX(calc(50% - 40px));
          }
        }
      }

      &::after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        // top: -8px;
        transition: 0.25s;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        &::after {
          background: $accent-green;
        }
        .title {
          gap: 9px;
          .desc {
            // margin-top: 9px;
            // margin-bottom: 9px;
            max-height: 300px;
          }
        }
      }
      &:nth-child(odd) {
        // background: rgba($color: red, $alpha: 0.25);

        .title {
          // top: 50%;
          top: 24px;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      &:nth-child(even) {
        // background: rgba($color: blue, $alpha: 0.25);

        .title {
          transform: translateX(-50%) translateY(50%);
          bottom: 24px;
          // top: 50%;

          flex-direction: column-reverse;
          margin-top: 0;
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
        .img {
          position: relative;
          right: -8px;
          top: 50%;
          transform: translateY(-50%);
          // img {
          // }
        }
        span {
          position: absolute;
          // top: 50%;
          left: 8px;
          // transform: translateX(-50%);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          width: 100%;
          text-align: center;

          bottom: 24px;
        }
      }
    }
  }
  &__inputs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .forms {
      display: flex;
      input {
        &::placeholder {
          @include input-font;
        }
        width: 220px;
        height: 53px;
        padding: 16px 24px 16px 24px;
        border-radius: 25px;
        margin-right: 24px;
      }
      button {
        width: 208px;
        height: 53px;
        padding: 16px 24px 16px 24px;
        gap: 8px;
        border-radius: 40px;
        background-color: $light-green;
        @include input-font;
      }
    }
  }
}
