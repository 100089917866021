.chat-app {
  height: calc(100vh - 64px);
  background: $white;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  @import './chat-head.scss';
  .chat-container {
    // background: red;
    width: 100%;
    // padding: 14px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: calc(100% - 55px);
    aside {
      min-width: 250px;
      flex: 1 0 0;
      background: rgb(31, 41, 55);
      height: 100%;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow: auto;
      color: $white;
      transition: 0.25s;
      .chat-user {
        background: transparent;
        width: 100%;
        // background: red;
        padding: 20px 14px;
        position: relative;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
        cursor: pointer;
        transition: 0.25s;
        &:hover {
          background: rgba($color: #bdbdbd, $alpha: 0.12);
        }
        // border-top: 1px solid transparent;
        .id {
          font-weight: 600;
          font-weight: 16px;
          margin-bottom: 4px;
          line-height: 100%;
        }
        .ip {
          font-size: 13px;
          line-height: 100%;
        }
        .new-msg {
          position: absolute;
          right: 14px;
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: $accent-green;
          transition: 0.25s;
          border: 1px solid rgba($color: $white, $alpha: 0.3);
        }
        &:last-child {
          border-color: transparent;
        }
        &.selected {
          background: rgba($color: #fff, $alpha: 0.15);
          border-color: rgba($color: $white, $alpha: 0.2);
          z-index: 2;
        }
      }
      // padding: ;
    }
    section {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      height: 100%;

      .clear {
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;
        background: $light-wite;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.25s;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        h2 {
          letter-spacing: 1px;
          color: rgba($color: $accent-green, $alpha: 0.65);
        }
      }

      &.clear-chat {
        .clear {
          opacity: 1;
          pointer-events: all;
        }
      }

      .chat-history {
        width: 100%;
        background: $light-wite;
        height: 100%;
        max-height: calc(100% - 97px);
        overflow: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $grey;

        padding: 14px;

        gap: 8px;

        .loader {
          width: 50px;
          height: 50px;
          aspect-ratio: 1;
          background: transparent;
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          border: 2px solid $accent-green;
          border-top-color: #fff;
          // transform: translate(-50%, -50%);
          translate: -50% -50%;
        }

        .time {
          position: absolute;
          bottom: 0px;
          right: 6px;
          font-size: 10px;
          font-weight: 700;
          color: rgba($color: $grey, $alpha: 0.4);
        }

        .me-msg {
          // width: 80%;
          max-width: 220px;
          background: #e5efed;
          padding: 6px;
          border-radius: 8px;
          position: relative;
          padding-bottom: 22px;
          min-width: 100px;
          transform: translateZ(0);
          align-self: flex-end;
        }
        .not-me-msg {
          max-width: 220px;
          background: #f6f6f6;
          background: #fff;

          padding: 6px;
          border-radius: 8px;
          position: relative;
          padding-bottom: 22px;
          min-width: 100px;
          transform: translateZ(0);
        }
      }
      .send {
        width: 100%;
        position: relative;
        textarea {
          width: 100%;
          background: $white;
          height: 90px;
          resize: none;
          padding: 9px;
          padding-right: 50px;
          outline: none;
          // background: red;
          border: 0;
          margin-bottom: 0;
        }
        button {
          background: $accent-green;
          position: absolute;
          right: 5px;
          bottom: 5px;
          // width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          // padding: 6px 8px;
          width: 40px;
          height: 40px;
          font-size: 12px;
          color: $white;
          cursor: pointer;
          text-transform: uppercase;
          border-radius: 50%;
          transition: 0.25s;
          &:hover {
            background: darken($color: $accent-green, $amount: 6);
          }
          svg {
            // margin-left: 3px;
            margin-right: 0;
            // width: 25px;
            height: auto;
            * {
              fill: $white;
            }
          }
        }
      }
      // background: green;
    }
  }
}
