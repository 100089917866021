.fast-contacts {
  position: fixed;
  z-index: 20;
  right: 24px;
  bottom: 24px;

  &.fast-whatsapp {
    bottom: calc(24px + 48px + 10px);
    transition: 0.25s;
    .show-head {
      background: transparent;
      padding: 0;
    }
  }
  &.hide-fast {
    opacity: 0;
    pointer-events: none;
  }
  .phone-call {
    position: absolute;
    bottom: 64px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.25s;
      border-radius: 8px;
      background: $light-green;
      height: 85%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        * {
          fill: $white;
        }
      }
      &:hover {
        background: rgba($color: $accent-green, $alpha: 0.6);
      }
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      padding: 8px;
      outline: none;
      padding-left: 48px;
    }
    .flag-dropdown {
      // border-radius: 30px 0px 0px 30px;
      background: unset;
      border: unset;
    }
  }

  .online-chat {
    width: 100%;
    max-width: 350px;
    background: $light-wite;
    // padding: 24px;

    border: 1px solid $accent-green;
    border-radius: 20px 20px 0 0;
    position: relative;
    bottom: -24px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s;
    box-shadow: 0 0 10px 2px rgba($color: #000000, $alpha: 0.35);
    .chat-head {
      padding: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 300px;
      background: $accent-green;
      z-index: 3;
      position: relative;
      .img {
        width: 30px;
        height: 30px;
        position: relative;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      span {
        font-size: 16px;
        font-weight: 700;
        font-style: 'Fixel';
        color: $grey;
        color: $white;
        margin-left: 9px;

        border-radius: 20px 20px 0 0;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-left: auto;
        .btn {
          border-radius: 8px;
          background: $white;
          transition: 0.25s;
          cursor: pointer;
          // padding: 8px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $accent-green;
          position: relative;
          font-weight: 700;
          // border-radius: ;
          &:hover {
            background: rgba($color: $white, $alpha: 0.66);
          }
          svg {
            * {
              fill: $accent-green;
            }
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }

          &.hide {
            transition: 0.25s;
            transform: rotate(90deg);
          }
        }
      }
    }

    .chat-content {
      // padding: 8px;
      padding-bottom: 0;
      padding-top: 0;
      transition: 0.25s;
      max-height: 360px;

      .history-overflow {
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: rgba($color: $grey, $alpha: 0.3);
          // border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $accent-green;
          border-radius: 10px;
        }
        // &::-webkit-scrollbar-thumb:hover {
        //     background: #b30000;
        //     width: 20px;
        // }
      }
      .history {
        padding: 16px 16px;
        background: $white;
        border-radius: 0;
        min-height: 300px;
        // height: 300px;
        // max-height: 300px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $grey;

        flex-direction: column-reverse;

        gap: 8px;
        .loader {
          width: 50px;
          height: 50px;
          aspect-ratio: 1;
          background: transparent;
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          border: 2px solid $accent-green;
          border-top-color: #fff;
          // transform: translate(-50%, -50%);
          translate: -50% -50%;
        }
        .time {
          position: absolute;
          bottom: 0px;
          right: 6px;
          font-size: 10px;
          font-weight: 700;
          color: rgba($color: $grey, $alpha: 0.4);
        }

        .me-msg {
          max-width: 80%;
          background: #e5efed;
          padding: 6px;
          border-radius: 8px;
          position: relative;
          padding-bottom: 22px;
          min-width: 100px;
          transform: translateZ(0);
        }
        .not-me-msg {
          max-width: 80%;
          align-self: flex-start;
          background: #f6f6f6;

          max-width: 80%;
          padding: 6px;
          border-radius: 8px;
          position: relative;
          padding-bottom: 22px;
          min-width: 100px;
          transform: translateZ(0);
        }

        .me-msg {
          & + .not-me-msg {
            // margin-top: 8px;
          }
        }
        .not-me-msg {
          & + .me-msg {
            // margin-top: 8px;
          }
        }
      }

      .send {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-top: 1px solid $accent-green;
        textarea {
          width: 100%;
          height: 55px;
          resize: none;
          padding: 8px;
          outline: none;
          padding-right: 40px;
          background: $light-wite;

          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-track {
            background: rgba($color: $grey, $alpha: 0.3);
            // border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: $accent-green;
            border-radius: 10px;
          }
        }

        .btn {
          position: absolute;
          right: 8px;
          height: 90%;
          width: 30px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          background: $accent-green;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          transition: 0.25s;
          svg {
            * {
              fill: $white;
            }
          }
          &:hover {
            background: rgba($color: $accent-green, $alpha: 0.65);
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }
    }

    &.minimize {
      .chat-head {
        .btns {
          .hide {
            transform: rotate(-90deg);
          }
        }
      }
      .chat-content {
        overflow: hidden;
        max-height: 0;
      }
    }
  }
  .show-head,
  .option {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: $accent-green;
    padding: 8px;
    transition: 0.25s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    pointer-events: all;
    border-color: $accent-green;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background: rgba($color: $accent-green, $alpha: 0.5);
    }

    svg {
      width: 100%;
      height: auto;
      * {
        transition: 0.25s;
      }
    }
  }
  .option {
    position: relative;
    overflow: visible;
    .tip {
      position: absolute;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      background: rgba($color: #000000, $alpha: 0.75);
      color: $white;
      white-space: nowrap;
      padding: 5px;
      border-radius: 8px;
      font-size: 14px;
      transition: 0.25s;
      opacity: 0;
    }
    @media (max-width: 1001px) {
      .tip {
        opacity: 1;
      }
    }

    &:hover {
      .tip {
        opacity: 1;
      }
    }
  }
  .show-head {
    background: $green-100;
    box-shadow: 0 0 10px 2px rgba($color: #000000, $alpha: 0.15);
    &:hover {
      background: rgba($color: $green-110, $alpha: 0.65);
    }
  }

  .select-option {
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.25s;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    .option {
      pointer-events: none;
    }
  }

  &.fast-phone {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.35);
    bottom: 0;
    right: 0;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 24px;
  }

  &.fast-menu,
  &.fast-phone {
    .show-head {
      padding: 4px 3px;
      background: transparent;
      width: 29px;
      height: 29px;
      border: 3px solid $accent-green;
      border-radius: 50%;
      svg {
        * {
          fill: $accent-green;
        }
      }
    }
  }
  &.fast-menu {
    .select-option,
    .option {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.fast-chat {
    .select-option {
      transition: 0;
      display: none;
    }
    .show-head {
      opacity: 0;
      pointer-events: none;
    }
    .online-chat {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (max-width: 360px) {
    display: none;
  }
}
