.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .types-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    border-radius: 30px;
    background: $light-wite;

    .btn {
      padding: 9.5px 20px;
      border-radius: 20px;
      transition: 0.25s;
      color: $grey;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        color: $white;
        background: rgba($color: $accent-green, $alpha: 0.75);
      }
      &.active {
        color: $white;

        background: $accent-green;
      }
    }
  }

  .btn {
    padding: 24px;
    padding-top: 0;
  }
  .products {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
    overflow-x: auto;
    width: 100%;
    padding: 24px;
    padding-bottom: 0;
    cursor: grab;
    padding-bottom: 5px;
    @media (max-width: 1000px) {
      padding-left: 0;
    }

    .loading-car {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 125px;
        height: auto;

        animation: carAnimation 3s linear infinite; /* Замените 2s на время, которое вы хотите чтобы анимация длилась */
      }
    }

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba($color: $grey, $alpha: 0.3);
      // border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $accent-green;
      border-radius: 10px;
    }

    .no-elems {
      width: 100%;
      text-align: center;
      font-size: 24px;
      margin-top: 20px;
      color: rgba($color: $accent-green, $alpha: 0.6);
    }

    .product {
      width: 365px;
      max-width: 365px;
      flex: 1 0 auto;
      border-radius: 30px;
      overflow: hidden;
      // padding-bottom: 24px;
      background: $light-wite;
      user-select: none;
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: 14px;
      }
      .notka {
        padding: 0 24px;
        padding-left: 30px;
        // margin-bottom: 14px;
        font-size: 13px;
        margin-top: 4px;
        // margin-top: -10px;
        color: rgba($color: #000000, $alpha: 0.5);
      }
      .price {
        padding: 0 24px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: $grey;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: auto;
        span {
          margin-left: 4px;
          color: $accent-green;
          font-weight: 800;
        }
      }
      .tags {
        display: flex;
        gap: 8px 16px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 24px;
        margin-bottom: 17px;
        .tag {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px;
          color: $grey;
        }
      }

      .title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
        text-transform: uppercase;
        color: $grey;
        padding: 0 24px;
        margin-bottom: 16px;
      }
      .img {
        width: 100%;
        height: 185px;
        position: relative;
        overflow: hidden;
        border-radius: 30px;
        margin-bottom: 24px;
        img {
          user-select: none;
          pointer-events: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .timer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 6px 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $accent-green;
          color: $white;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px;
          transition: 0.25s;

          .space {
            margin: 3px;
          }

          svg {
            margin-right: 8px;
          }
        }

        &:hover {
          .timer {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

@keyframes carAnimation {
  0% {
    transform: translateX(0) scale(1) scaleX(1);
  }
  50% {
    transform: translateX(40%) scale(1.1) scaleX(1);
  }
  51% {
    transform: translateX(40%) scale(1.1) scaleX(-1);
  }
  99% {
    transform: translateX(0) scale(1) scaleX(-1);
  }
  100% {
    transform: translateX(0) scale(1) scaleX(1);
  }
  // 20% {
  //     transform: translateX(-10%) scale(0.9) rotate(0);
  // }
  // 30% {
  //     transform: translateX(10%) scale(1.1) rotate(15deg);
  // }
  // 40% {
  //     transform: translateX(-10%) scale(0.9) rotate(0);
  // }
  // 100% {
  //     transform: translateX(10%);
  // }
}
