.catalog {
  display: flex;
  margin-bottom: 80px;
  padding: 0px 16px;
  padding-top: 48px;

  &__body {
    width: 100%;
  }
  &__links {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: $desk-md) {
      flex-direction: column-reverse;
      align-items: start;
    }
    @media (max-width: $mobile-size) {
      margin-bottom: 16px;
    }
    &_mob-hidden {
      @media (max-width: $mobile-size) {
        display: none;
      }
    }
    &_mob-view {
      @media (min-width: $mobile-size) {
        display: none;
      }
    }
  }
  &__info-price {
    position: relative;
    width: 100%;
    max-width: 796px;
    height: 61px;
    padding: 13px 24px 13px 60px;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    align-items: center;
    @media (max-width: $desk-md) {
      height: unset;
    }
    @media (max-width: $mobile-size) {
      padding: 12px 12px 12px 48px;
    }

    .icon {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: $mobile-size) {
        left: 12px;
      }
    }

    .info-price__text {
      @include captian1-geologica-desk;
      font-weight: 300;
    }
  }
  &__messagers {
    display: flex;
    align-items: center;
    @media (max-width: $mobile-size) {
      width: 100%;
      justify-content: space-between;
    }
    span {
      @include p1-geologica-desk;
      margin-right: 24px;
      @media (max-width: $mobile-size) {
        @include p3-geologica-mobile;
      }
    }
    .messagers {
      display: flex;
      align-items: center;
      gap: 12px;
      .btns {
        a {
          width: 48px;
          height: 48px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          background: $green-100;

          svg {
            max-width: 18px;
            height: auto;
            @media (max-width: $mobile-size) {
              width: 12px;
            }
          }
          @media (max-width: $mobile-size) {
            width: 32px;
            height: 32px;
          }
        }
        &:last-child {
          margin-right: 0px;
          @media (max-width: $mobile-size) {
            display: none;
          }
        }
      }
    }
  }
  &__cars {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px 27px;
    @media (max-width: $mobile-size) {
      gap: 48px;
    }
  }

  .button-then {
    height: 77px;
    padding: 24px 10px 24px 10px;
    border-radius: 20px;
    flex: 0 0 100%;
    background-color: $green-100;
    @include h4-fixel-desk;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    &__icon {
      transform: rotate(135deg);
      * {
        width: 16px;
        height: 16px;
        fill: $white;
      }
    }
  }

  @media (max-width: $mobile-size) {
    flex-wrap: wrap;
  }
}
