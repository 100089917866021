.expenses-delivery {
  padding: 48px 32px;
  @media (max-width: 1000px) {
    padding: 24px 0px;
  }

  .row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;
    width: 100%;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .btn {
      margin-left: auto;
    }

    .column {
      min-width: 0;
      flex: 1 0 0;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      border-radius: 20px;
      background: $light-wite;
      @media (max-width: 1000px) {
        width: 100%;
        // mb10
      }
      @media (max-width: 1201px) {
        flex-wrap: wrap;
      }
      .left {
        width: 100%;
        padding: 32px;
        @media (max-width: 1000px) {
          padding: 16px;
        }
        .title {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 33.6px */
          letter-spacing: 0.96px;
          color: $grey;
          margin-bottom: 16px;
        }
        .tags {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 16px;
          .tag {
            padding: 8px;
            border-radius: 20px;
            background: var(--grey-green, #e5efed);
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
            color: $grey;
          }
        }
      }
      .right {
        min-width: 290px;
        position: relative;
        @media (max-width: 1201px) {
          // display: none;
          width: 100%;
          height: 200px;
          margin-top: auto;
        }
        img {
          border-radius: 20px;
          object-fit: cover;
        }
      }
    }
  }
}
