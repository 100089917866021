@import '../mixins.scss';

$mobile: 1000px;

.tous {
  &__content {
    display: flex;
    flex-wrap: wrap;
    min-height: 484px;
    padding: 48px 15px 48px 32px;

    .block-left,
    .block-right {
      width: 50%;
      flex: 1 1 50%;
    }
    .block-left {
      padding-right: 8px;

      .chart-block {
        display: flex;

        .sales-chart {
          width: 72%;
          height: 348px;
          margin-right: 16px;
          background-position: top;
        }

        .partenrs {
          width: 50%;
          min-width: 350px;
          white-space: pre-line;
          .tous__img-block {
            background-size: auto !important;
            // background-position: 190% 45% !important;
            border-radius: unset;
          }
        }
      }
    }
    .block-right {
      padding-left: 8px;
      display: flex;

      .end-block {
        width: 50%;
        padding-left: 16px;

        .office-block {
          margin-bottom: 16px;
        }

        .tous__block {
          height: 416px;
          .tous__img-block {
            background-position: center 63% !important;
          }
        }
      }
    }
  }

  &__block {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include background;
  }

  &__head {
    position: relative;
    z-index: 1;
    color: white;
    padding: 24px;
    margin-bottom: 24px;
    @media (max-width: $mobile-size) {
      margin-bottom: unset;
    }
  }

  &__digits {
    @include h1-fixel-desk;
  }

  &__subtitle {
    @include p1-geologica-desk;
    display: flex;
    justify-content: space-between;
    @media (max-width: $mobile-size) {
      @include p3-geologica-mobile;
    }
  }

  &__img-block {
    position: relative;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__city-item {
    display: flex;
    align-items: center;
    margin-right: 4px;
    @include captian1-geologica-desk;
    padding-top: 32px;
  }
}

.sales-avto {
  margin-bottom: 16px;
  height: 484px;
  @media (max-width: $mobile-size) {
    height: 320px;
  }
}

.regular-customers {
  width: 50%;
  height: 100%;

  .tous__block {
    height: 100%;
    .tous__head {
      margin-bottom: 35px;
    }
  }
}

@media (max-width: 1300px) {
  .tous__content {
    .block-left,
    .block-right {
      flex-basis: 100%;
      width: 100%;
    }

    .block-left {
      margin-bottom: 16px;
      .chart-block {
        .partenrs {
          .tous__img-block {
            background-position: center !important;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .tous__content {
    flex-direction: column;
    padding: 24px 12px;

    .block-left,
    .block-right {
      width: 100%;
    }

    .block-left {
      margin-bottom: 16x;
      .chart-block {
        flex-wrap: wrap;
        gap: 16px;

        .sales-chart {
          margin-right: 0px;
          width: 100%;
          height: 320px;
        }

        .partenrs {
          width: 100%;
          height: 260px;
          margin-bottom: 12px;
          min-width: unset;
          .tous__img-block {
            background-size: contain !important;
            background-position: 15px 50% !important;
          }
        }
      }
      padding-right: unset;
    }
    .regular-customers {
      height: 435px;
      .tous__head {
        margin-bottom: 8px;
      }
    }

    .block-right {
      flex-wrap: wrap;
      gap: 16px;
      padding-left: 0px;
      .regular-customers {
        width: 100%;
      }
      .end-block {
        padding-left: 0px;
        width: 100%;
        .office-block {
          height: 371px;
          .tous__block {
            height: 100%;
            .tous__digits {
              @include h2-fixel-mobile;
              margin-bottom: 12px;
            }
            .tous__subtitle {
              flex-wrap: wrap;
              justify-content: start;
              gap: 12px;
              .tous__city-item {
                padding-top: unset;
                // margin-right: 12px;
              }
            }
          }
          margin-bottom: 12px;
        }

        .sto-block {
          .tous__block {
            height: 100%;
            .tous__digits {
              @include h2-fixel-mobile;
            }
          }
          height: 287px;
        }
      }
    }
  }
}
