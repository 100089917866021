footer {
  position: relative;
  width: 100%;
  background: $grey;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  color: $white;
  gap: 54px;
  padding: 24px;
  border-radius: 20px 20px 0px 0px;
  flex-wrap: wrap;
  overflow: hidden;
  .column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 156px;
      svg {
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      gap: 8px;
      li {
        width: 40px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: rgba(239, 239, 239, 0.1);
        cursor: pointer;
        transition: 0.25s;

        svg {
          * {
            transition: 0.25s;
          }
        }

        &:hover {
          svg {
            * {
              fill: $accent-green;
            }
          }
        }
      }
    }
    .grey-container {
      border-radius: 20px;
      background: rgba(239, 239, 239, 0.1);
      padding: 24px;
      color: $white;
      width: 100%;
      height: 100%;
      .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        a {
          // text-transform: lowercase;
          transition: 0.25s;
          text-transform: capitalize;

          &:hover {
            color: $accent-green;
          }
        }
      }

      .work-time {
        display: block;
        margin-bottom: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-width: 255px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        li {
          min-width: 340px;

          transition: 0.25s;
          cursor: pointer;
          // background: red;
          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
              color: $accent-green;

              svg {
                * {
                  fill: $accent-green;
                }
              }
            }
          }
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.64px;
            svg {
              * {
                transition: 0.15s;
              }
              margin-right: 8px;
            }
          }
        }
      }

      .title {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $light-wite;
        margin-bottom: 24px;
      }
    }
    .map {
      width: 100%;
      min-width: 500px;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      position: relative;
    }
  }
}
.copyrights {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  // font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px;
  background: $grey;
  width: 100%;
  color: $white;
}

@media (max-width: $mobile-size) {
  footer {
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 20px;
    position: relative;
    gap: 24px 0;
    justify-content: center;

    .column {
      width: 100%;
      .logo {
        width: 100px;
        height: 81.42px;
        padding: 16.56px 12.69px 16.56px 13px;
      }
      .map {
        display: none;
      }
      .grey-container {
        padding: 24px 12px;
        .contacts {
          padding-top: unset;
          li {
            min-width: unset !important;
            a {
              // flex-direction: column;
              span {
                margin-right: 8px;
              }
            }
          }
        }
      }
      .socials {
        flex-wrap: wrap;
        justify-content: start;
      }
    }
  }
}
