@import '../mixins.scss';

.product {
  width: 334px;
  flex: 0 0 334px;
  border-radius: 16px;
  background: $light-wite;
  user-select: none;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: $mobile-size) {
    width: 276px;
    max-width: 276px;
    min-width: unset;
  }
  .hot-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 62px;
    width: 116px;
    z-index: 1;
    transform: translate(12%, -50%);
  }

  .img {
    width: 100%;
    height: 186px;
    position: relative;
    overflow: hidden;
    border-radius: 16px 16px 0px 0px;
    img {
      user-select: none;
      pointer-events: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .timer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6px 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $accent-green;
      color: $white;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      transition: 0.25s;

      svg {
        margin-right: 8px;
      }
      display: none;
    }

    &:hover {
      .timer {
        opacity: 0.3;
      }
    }
    @media (max-width: $mobile-size) {
      width: 276px;
      height: 140px;
      border-radius: 16px;
    }
  }
  .body {
    flex-grow: 1; /* Растягивает последний элемент, заполняя оставшееся пространство */
    flex-shrink: 0;
    background-color: white;
    padding: 24px 0px 0px 0px;
    width: 334px;
    border-radius: 16px;
    padding-bottom: 24px;
    margin-top: -24px;
    position: relative;
    font-family: Geologica;
    display: flex;
    flex-direction: column;
    .notka {
      padding-left: 25px;
      margin-bottom: 14px;
    }
    .btn {
      width: 100%;
      align-self: center;
      padding: 0 24px;
      button {
        background: $green-110;
        @media (max-width: $mobile-size) {
          width: 144px;
          height: 36px;
          padding: 10px 20px 10px 20px;
          gap: 8px;
          border-radius: 40px;
          background: $green-100;
          @include buttons-fixel-mobile;
        }
      }
      @media (max-width: $mobile-size) {
        padding: 0px;
      }
    }
    .price {
      padding: 0 24px;
      @include p3-geologica-desk;
      line-height: 21px;
      color: #222222b2;
      margin-bottom: 24px;
      @media (max-width: $mobile-size) {
        @include captian1-geologica-mobile;
        padding: 0px;
        margin-bottom: 16px;
        height: 40px;
      }
      span {
        display: block;
        margin-top: 8px;
        color: $green-100;
        @include p1-geologica-desk;
        text-align: left;
        @media (max-width: $mobile-size) {
          @include p3-geologica-mobile;
          margin-bottom: 0px;
          margin-top: 4px;
          color: $green-100;
        }
      }
      @media (max-width: $mobile-size) {
        margin-bottom: 13px;
      }
    }
    .tags {
      display: flex;
      gap: 8px 16px;
      flex-direction: column;
      padding: 0 24px;
      margin-bottom: 17px;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      flex-wrap: wrap;
      .tag {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        @include captian1-geologica-desk;
        color: $text-secondary;

        .text-primary {
          color: $grey;
        }
        @media (max-width: $mobile-size) {
          @include captian2-geologica-mobile;
        }
      }
      @media (max-width: $mobile-size) {
        padding: 0px;
        flex-direction: row;
        margin-bottom: 12px;
        justify-content: flex-start;
      }
    }

    .title {
      @include p1-geologica-desk;
      color: $grey;
      padding: 0 24px;
      margin-bottom: 16px;
      text-align: left;
      @media (max-width: $mobile-size) {
        padding: 0px;
        margin-bottom: 12px;
      }
    }

    @media (max-width: $mobile-size) {
      width: 276px;
      margin-top: -16px;
    }
  }
}

@media (max-width: $mobile-size) {
  .product {
    min-width: 276px;
    .body {
      width: 276px;
      padding: 10.2px 8px 10.2px 8px;
      gap: 16px;
      border-radius: 8px 8px 16px 16px;
    }
  }
}
