@import '../mixins.scss';

.contacts {
  padding-top: 48px;
  @media (min-width: $mobile-size) {
    padding-top: 0px;
  }
  .contacts-container {
    padding: 48px 32px 32px 32px;
    @media (max-width: $mobile-size) {
      padding: 24px 12px;
    }
    &__top {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 32px;
      margin-bottom: 32px;
      h1 {
        @include h1-fixel-desk;
        text-transform: uppercase;
        margin-right: 120px;
        @media (max-width: 1481px) {
          width: 100%;
          margin-right: 0px;
        }
      }

      .block {
        border-radius: 20px;
        background: $light-wite;
        padding: 24px;
        flex: 1;

        .title {
          @include p1-geologica-desk;
          color: $text-primary-black;
          margin-bottom: 32px;
        }
        .work-time {
          display: flex;
          width: 220px;
          justify-content: space-between;
        }
        .work-time,
        .contacts-list {
          margin-bottom: 8px;
          @include buttons-fixel-desk;
        }
        .contacts-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 9px;
          &__link {
            max-width: 363px;
          }
          li {
            a {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              &:hover {
                color: $accent-green;
                svg {
                  * {
                    fill: $accent-green;
                  }
                }
              }
            }

            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 255px;
            width: 100%;
            transition: 0.25s;
            cursor: pointer;

            @media (max-width: $desk-hd) {
              min-width: unset;
            }
            svg {
              margin-right: 8px;
              width: 24px;
              height: auto;
              path {
                fill: black;
              }
              * {
                transition: 0.25s;
              }
            }

            span {
              @include buttons-fixel-desk;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              &:nth-child(2) {
                margin-left: auto;
              }
              svg {
                margin-right: 8px;
                width: 24px;
                height: auto;
                * {
                  transition: 0.25s;
                }
              }
            }
          }
        }
      }
    }
  }

  .contacts-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: $desk-hd) {
      justify-content: unset;
    }
    &__item {
      background: #f6f6f6;
      width: 236px;
      height: 72px;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: $mobile-size) {
        width: 100%;
      }
      .contacts-social__link {
        @include buttons-fixel-desk;
        gap: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-primary-black;
        span {
          width: 24px;
          height: 24px;
        }
        path {
          fill: black;
        }
      }
    }
  }

  .contacts-map {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    height: 400px;
    justify-content: center;
    padding: 32px 47px;
    .img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      iframe {
        // position: absolute;
        width: 100%;
        height: 100%;
        border: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .info-container {
      position: relative;
      height: 100%;
      border-radius: 100px;
      background: #ffffff80;
      display: flex;
      align-items: center;
      padding: 24px 48px;
      max-width: 776px;
      max-height: 101px;
      margin-top: auto;
      min-width: 276px;

      .title {
        @include h4-fixel-desk;
        color: $white;
        margin-right: 24px;
        text-transform: uppercase;
        @media (max-width: $mobile-size) {
          @include captian1-geologica-mobile;
          margin-bottom: 12px;
          text-transform: none;
          width: 150px;
        }
      }
      .btn {
        @include buttons-fixel-desk;
        width: 158px;
        height: 53px;
        gap: 8px;
        border-radius: 40px;
        background-color: $green-100;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      @media (max-width: $mobile-size) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 12px;
        border-radius: 16px;
      }
    }
  }
}
