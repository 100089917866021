.managers {
  padding: 48px 0px;
  @media (max-width: $mobile-size) {
    padding: 24px 12px;
  }
  &__title {
    margin-bottom: 48px;
    color: $text-primary-black;
    @include h2-fixel-desk;
    text-align: center;
    @media (max-width: $mobile-size) {
      margin-bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 48px;
    align-items: stretch;
    row-gap: 16px;
    @media (max-width: $mobile-size) {
      margin-bottom: unset;
      gap: 16px;
    }
  }

  &__messagers {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    @media (max-width: $mobile-size) {
      display: block;
    }

    .messagers__block {
      display: flex;
      @media (max-width: $mobile-size) {
        display: block;
      }

      .messagers__icons {
        display: flex;
      }

      .messagers__text {
        width: 500px;
        margin-right: 24px;
        @include p1-geologica-desk;
        @media (max-width: $mobile-size) {
          @include p3-geologica-mobile;
          margin-bottom: 12px;
          margin-right: unset;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-right: 12px;
        a {
          width: 64px;
          height: 64px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          background: $green-100;

          svg {
            max-width: 24px;
            height: auto;
            @media (max-width: $mobile-size) {
              width: 12px;
            }
          }
          @media (max-width: $mobile-size) {
            width: 32px;
            height: 32px;
          }
          &:hover {
            background-color: $green-110;
          }
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.manager {
  height: 100%;
  background: #f6f6f6;
  margin-right: 16px;
  border-radius: 24px;
  &__contacts {
    margin-bottom: 24px;
    padding: 0px 24px;
  }
  &__img {
    position: relative;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 16px;
    img {
      @media (max-width: $mobile-size) {
        height: 280px;
        height: auto;
      }
    }
    @media (max-width: $mobile-size) {
      // height: unset;
    }
  }
  &__title {
    @include p1-geologica-desk;
    text-align: center;
    margin-bottom: 12px;
    color: $text-primary-black;
  }
  &__phone {
    background: #ffffff;
    height: 48px;
    padding: 12px 32px 12px 32px;
    gap: 8px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include buttons-fixel-desk;
    text-align: center;
    svg {
      @media (max-width: $mobile-size) {
        width: 12px;
        height: 12px;
      }
    }
    @media (max-width: $mobile-size) {
      width: 174px;
      height: 36px;
      padding: 10px 20px 10px 20px;
      gap: 8px;
      margin: auto;
    }
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }

  @media (max-width: $mobile-size) {
    margin-right: 0px;
  }
}
